import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cardOrientation } from "assets/util/common/cardOrientation";
import { rootFont } from "assets/util/common/rootFont";
import "./payment.css";
import actions from "Action/action";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RootSelect from "assets/util/Root/RootSelect";
import API from "util/API/api";
import { useDispatch } from "react-redux";
import { callGet, callSet } from "common/local";
import { postAPIData } from "Hooks/Services";
import { toast } from "react-hot-toast";
import Loader from "Pages/Loader";
import { POLICY_BASE_URL } from "server/server";

const ValidatorSchema = yup
  .object()
  .shape({
    UHID: yup.string().required("UHID is required"),
    hospitaName: yup.string().required("Hospital is required"),
    advanceType: yup.string().required("Advance-Type is required"),
    amount: yup
      .string()
      .required("Amount is required")
      .test(
        "checkAmount",
        "Enter an amount between 1 to 1000000",
        (val) => +val > 0 && +val <= 1000000
      ),
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "Please enter a valid email"
      ),
  })
  .when((values, schema) => {
    if (values?.UHID === "other") {
      return schema.shape({
        typeUHID: yup
          .string()
          .min(9, "Enter a valid UHID")
          .max(9, "Enter a valid UHID")
          .required("UHID is required"),
      });
    }
  });
function Payment() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [amount, setAmount] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [uhidVal, setUHIDVal] = useState("");
  const [uhidResp, setUhidResp] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [UHIDOptions, setUHIDOptions] = useState([]);
  const userData = callGet("auth");
  const mainDiv = { ...cardOrientation, minHeight: "50%" };
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      UHID: "",
      hospitaName: "",
      advanceType: "",
      amount: "",
      email: "",
      typeUHID: "",
    },
    resolver: yupResolver(ValidatorSchema),
  });
  const UHIDInfo = async (data) => {
    try {
      setInfoLoading(true);
      const resp = await postAPIData({
        ...API.getuhidinfo({ ...callGet("auth"), UHID: data }),
      });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      const respData = resp?.data?.Data?.[0];
      setUhidResp(respData);
      setValue("email", respData?.EmailId?.trim() || "");
    } catch (err) {
      console.error(err);
      toast.error(err?.FriendlyMessage || "Something went wrong");
    } finally {
      setInfoLoading(false);
    }
  };
  const getFormData = async (data) => {
    if (data?.UHID === "other") {
      if (!uhidResp?.Name) {
        toast.error("Please enter valid UHID");
        return;
      }
      delete data["email"];
      const payload = {
        ...data,
        ...uhidResp,
        EmailId: data?.email,
      };
      dispatch(actions.A_getPaymentData(payload));
      navigate("PayementSummary");
    } else {
      const UHID_data = patientData?.find((e) => e?.UHID == selectedOption);
      UHID_data.EmailId = data?.email;
      delete data["email"];
      dispatch(
        actions.A_getPaymentData({
          ...data,
          ...UHID_data,
        })
      );
      navigate("PayementSummary");
    }
  };
  const setOptions = (data) => {
    let Arr = [];
    for (let key of data) {
      Arr.push({
        name: `${key?.UHID} - ${key?.Name}`,
        value: key?.UHID,
      });
    }
    return Arr;
  };
  useEffect(() => {
    setValue("typeUHID", "");
    setUHIDVal("");
    setUhidResp({});
    setValue(
      "email",
      patientData?.find((e) => e?.UHID == selectedOption)?.EmailId?.trim() || ""
    );
    clearErrors("email");
  }, [selectedOption]);
  const getHospitals = async (data) => {
    try {
      setLoading(true);
      const resp_UHIDOptions = await postAPIData({
        ...API.getuhidlistbymobile(data),
      });
      if (!resp_UHIDOptions?.data?.IsSuccessful) {
        toast.error(
          resp_UHIDOptions?.data?.FriendlyMessage || "Something went wrong"
        );
        return;
      }
      setPatientData(resp_UHIDOptions?.data?.Data);
      setUHIDOptions(setOptions(resp_UHIDOptions?.data?.Data));
      const resp_ActiveHospitals = await postAPIData({
        ...API.getactivehospitals(data),
      });
      if (!resp_ActiveHospitals?.data?.IsSuccessful) {
        toast.error(
          resp_ActiveHospitals?.data?.FriendlyMessage || "Something went wrong"
        );
        return;
      }
      setHospitalOptions(
        resp_ActiveHospitals?.data?.Data[0]?.Hospitals.map((e) => ({
          name: e?.FacilityName,
          value: e?.FacilityId,
        }))
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setValue("hospitaName", userData?.hospitalId || "");
  }, [userData]);
  useEffect(() => {
    getHospitals({ ...userData });
    const txnId = `PA1${new Date().getTime()}`;
    callSet({
      name: "auth",
      data: {
        ...userData,
        TxnID: txnId,
      },
    });
  }, []);
  return (
    <div className="container-fluid p-0 mt-2" style={mainDiv}>
      <div className="w-100 p-5">
        <h4 className="heading fw-bold" style={rootFont}>
          ONLINE PAYMENT
        </h4>
        <p className="fw-bold pt-2 pb-2">Patient Name</p>
        <div className="row">
          <RootSelect
            bootstrap={{
              divCss: "col-md-4",
              selectCss: "form-control rootselect",
            }}
            placeholder={"UHID*"}
            options={[...UHIDOptions, { name: "OTHERS", value: "other" }]}
            name={"UHID"}
            register={register}
            error={errors}
            setSelectedOption={setSelectedOption}
          />
          <div className="col-md-4">
            <input
              disabled
              value={
                selectedOption === "other"
                  ? uhidResp?.Name || ""
                  : patientData?.find((e) => e?.UHID == selectedOption)?.Name ||
                    ""
              }
              type="text"
              className="form-control p-2"
              placeholder="Patient Name"
            />
          </div>
          <div className="col-md-4">
            <input
              {...register("email")}
              name="email"
              type="text"
              className="form-control p-2"
              placeholder="Email*"
            />
            {errors?.["email"] && (
              <p className="errorText">
                {errors?.["email"]?.message?.toString()}
              </p>
            )}
          </div>
          {getValues("UHID") === "other" && (
            <div className="col-md-4">
              <input
                {...register("typeUHID", {
                  onChange: (e) => {
                    if (e?.target?.value?.length > 9) {
                      setValue("typeUHID", uhidVal);
                      return;
                    }
                    setUHIDVal(e?.target?.value);
                    if (e?.target?.value?.length === 9) {
                      UHIDInfo(e?.target?.value);
                    }
                  },
                })}
                name="typeUHID"
                type="number"
                min={0}
                max={999999999}
                className="form-control p-2"
                placeholder="Enter UHID*"
              />
              {errors?.["typeUHID"] && (
                <p className="errorText">
                  {errors?.["typeUHID"]?.message?.toString()}
                </p>
              )}
            </div>
          )}
        </div>
        <p className="fw-bold pt-2 pb-2">Payment Details</p>
        <div className="row">
          <RootSelect
            bootstrap={{
              divCss: "col-md-4",
              selectCss: "form-control p-2 rootselect",
            }}
            placeholder={"Hospital Name*"}
            options={hospitalOptions}
            name={"hospitaName"}
            register={register}
            error={errors}
          />
        </div>
        <div className="row">
          <RootSelect
            bootstrap={{
              divCss: "col-md-4 pt-0",
              selectCss: "form-control rootselect",
            }}
            placeholder={"Advance Type*"}
            options={[
              { name: "OPD Collection", value: "1" },
              {
                name: "IPD Collection",
                value: "2",
              },
            ]}
            name={"advanceType"}
            register={register}
            error={errors}
          />
          <div className="col-md-4">
            <input
              {...register("amount", {
                onChange: (e) => {
                  let text = e?.target?.value
                    ?.replaceAll("e", "")
                    .replaceAll("+", "")
                    .replaceAll("-", "");
                  if (text > 1000000) {
                    setValue("amount", amount);
                    return;
                  }
                  setValue("amount", text);
                  setAmount(text);
                },
              })}
              min={1}
              max={1000000}
              name="amount"
              type="number"
              className="form-control p-2"
              placeholder="Amount*"
            />
            {errors?.["amount"] && (
              <p className="errorText">
                {errors?.["amount"]?.message?.toString()}
              </p>
            )}
          </div>
          <div className="col-md-4">
            <select
              disabled
              name="currency"
              id="rootField"
              className="form-select form-control"
              aria-label="Default select example"
            >
              <option selected>INR</option>
            </select>
          </div>
        </div>
        <div className="pt-3">
          <button
            type="button"
            disabled={loading || infoLoading}
            class="btn btn-primary"
            style={{ borderRadius: "50px", width: "150px" }}
            onClick={handleSubmit(getFormData)}
          >
            Submit
          </button>
        </div>
        <p className="para pt-4">
          <u
            onClick={() => {
              navigate("/dashboard/refund-policy");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Appointment Cancellation And Payment Refund Policy
          </u>
        </p>
        {(loading || infoLoading) && (
          <div className="loader-background">
            {" "}
            <div className="centerLoader1">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
