import React, { useEffect, useState } from "react";
import "./AppointmentCategories.css";
import usePost from "../../../Hooks/usePost";
import API from "../../../util/API/api";
import actions from "../../../Action/action";
import { useDispatch, useSelector } from "react-redux";
import { callGet } from "../../../common/local";
import { useNavigate } from "react-router-dom";
import { postThunk } from "../../../Action/middleware/thunk";
import { cardOrientation } from "../../../assets/util/common/root";
import { Specialty } from "../../../server/server";
import { toast } from "react-hot-toast";
import Loader from "Pages/Loader";
import { filterByDcotorName, filterBySpecialityrName } from "./utils";
import DoctorCard from "../DoctorList/DoctorCard/DoctorCard";

const AppointmentCategories = () => {
  const dispatch = useDispatch();
  const [loading, getData, setAppointAPI] = usePost();
  const allSpecialtiData = useSelector((state) => state?.R_getallspecialties);
  const [allSpecialities, setAllSpecialities] = useState(allSpecialtiData);
  const searchText = useSelector((state) => state?.R_searchText);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [filteredSpeciality, setFilteredSpeciality] = useState([]);
  useEffect(() => {
    if (getData) {
      if (getData?.IsSuccessful) {
        dispatch(actions.A_getallspecialties(getData?.Data));
      } else {
        dispatch(actions.A_getallspecialties([]));
      }
    }
  }, [getData]);

  useEffect(() => {
    dispatch({ type: "CLEAR_TAKE_APPOINT" });
    var body = { ...callGet("auth") };
    body.HospitalId = searchText?.hospitalId || "";
    setAppointAPI(API.getallspecialties(body));
  }, [searchText?.hospitalId]);
  useEffect(() => {
    setAllSpecialities(allSpecialtiData);
  }, [allSpecialtiData]);
  useEffect(() => {
    if (searchText?.text?.trim()) {
      setFilteredDoctors(
        filterByDcotorName(allSpecialtiData, searchText?.text)
      );
      setFilteredSpeciality(
        filterBySpecialityrName(allSpecialtiData, searchText?.text)
      );
    }
  }, [searchText?.text, allSpecialities]);

  return (
    <div
      className="container-fluid"
      style={{
        ...cardOrientation,
        backgroundColor: "transparent",
        minHeight: "50vh",
        maxHeight: "160vh",
        overflowY: "scroll",
      }}
    >
      {loading ? (
        <div className="centerLoader1">
          <Loader />
        </div>
      ) : (
        <div className="row display-flex flex-wrap">
          {!searchText?.text?.trim() &&
            (allSpecialities?.length > 0 ? (
              allSpecialities?.map((e, index) => (
                <Cards key={index} title={e} />
              ))
            ) : (
              <h3 className="text-center">No Data Found</h3>
            ))}
          {searchText?.text?.trim() &&
            filteredDoctors?.length > 0 &&
            filteredDoctors?.map((e, i) => {
              return <DoctorCard key={i} data={e} />;
            })}
          {searchText?.text?.trim() &&
            (filteredSpeciality?.length > 0 ? (
              filteredSpeciality?.map((e, i) => {
                return <Cards key={i} title={e} />;
              })
            ) : (
              <p className="text-center">
                {!filteredDoctors?.length && "No Data Found"}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

function Cards({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const imgUrl = (path) => `${Specialty()}/${path}`;
  function getdoctorBySId() {
    navigate("doctList");
    const authData = callGet("auth");
    var body = {
      ...authData,
      SpecialityId: title?.SpecialisationId,
      hospitalId: authData?.selectedHospitalId,
    };
    dispatch(postThunk(API.A_GetDoctorsListBySpecialityID(body)));
  }
  return (
    <div className="col-xl-3 col-md-4 col-sm-6 py-3" onClick={getdoctorBySId}>
      <div id="Apntmnt_categ" className="card shadow border-0 cat-card">
        <div className="card-body text-center pt-5 pb-4">
          <img
            id="profile"
            draggable="false"
            src={imgUrl(title?.SpecialisationUrl)}
            alt=""
          />
          <p>{title?.SpecialisationName}</p>
        </div>
      </div>
    </div>
  );
}
export default AppointmentCategories;
