import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { toast } from "react-hot-toast";
import useLogOut from "Hooks/useLogout";
import { callGet } from "common/local";
import API from "util/API/api";
import dummyProfile from "assets/image/profile.png";

import axios from "axios";

import {
  font,
  cardOrientation,
  rootButton,
} from "../../assets/util/common/root";
import { postAPIData, postFormAPIData } from "Hooks/Services";
import PatientProfile from "./PatientProfile";
import Loader from "Pages/Loader";
const ValidationSchema = yup
  .object()
  .shape({
    FullName: yup.string().required("Name is required"),
    // MaritalStatus: yup.string().required("This field is required"),
    // Gender: yup.string().required("This field is required"),
    // FileName: yup.string().required("This field is required"),
  })
  .when((values, schema) => {
    if (values?.EmailId) {
      return schema?.shape({
        EmailId: yup
          .string()
          .matches(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            "Please enter a valid email"
          ),
      });
    }
  });
const MyProfile = () => {
  let formData = new FormData();
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, callLogout] = useLogOut();
  const [editPage, setEditPage] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const profileRef = useRef();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      FullName: "",
      MaritalStatus: "",
      Gender: "",
      EmailId: "",
      FileName: "",
    },
    resolver: yupResolver(ValidationSchema),
  });
  const getProfileDetails = async () => {
    try {
      setLoading(true);
      const authData = callGet("auth");
      const dashBoardResp = await postAPIData({
        ...API.getdashboarddata({
          ...authData,
          FacilityId: authData?.hospitalId,
        }),
      });
      const getDashboardData = dashBoardResp?.data;
      if (getDashboardData?.IsSuccessful) {
        setDashboardData(getDashboardData.Data[0]);
      } else {
        callLogout("logout");
        toast.error(getDashboardData?.FriendlyMessage || "Login again");
      }
      const resp = await postAPIData({
        ...API.getprofiledata({
          ...authData,
          IsDoctor: 0,
          FacilityId: authData?.hospitalId,
        }),
      });
      const getData = resp?.data;
      if (getData?.IsSuccessful) {
        setValue("EmailId", getData?.Data?.[0]?.EmailId || "");
        setValue("FileName", getData?.Data?.[0]?.ProfilePic || "");
        setValue("FullName", getData?.Data?.[0]?.FullName || "");
        setValue("MaritalStatus", getData?.Data?.[0]?.MaritalStatus || "");
        setValue("Gender", getData?.Data?.[0]?.Gender || "");
        setProfilePicUrl(
          getData?.Data?.[0]?.ProfilePic
            ? `https://patientappuat.marengoasia.com/Files/User/` +
                getData?.Data?.[0]?.ProfilePic
            : dummyProfile
        );
        setProfileDetails(getData?.Data?.[0]);
      } else {
        toast.error(getData?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!editPage) {
      getProfileDetails();
    }
  }, [editPage]);
  const saveProfileData = async (data) => {
    try {
      setLoading(true);
      const resp = await postFormAPIData(API.saveProfile(data));
      if (resp?.data?.IsSuccessful) {
        setEditPage(false);
        reset();
        formData.forEach((value, field) => {
          formData.delete(field);
        });
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
      err?.FriendlyMessage && toast.error(err?.FriendlyMessage);
    } finally {
      setLoading(false);
    }
  };
  const getFormData = async (data) => {
    const authData = callGet("auth");
    data.FileName = profilePicUrl;
    formData.append("FileName", imageFile);
    formData.append("FullName", data?.FullName || "");
    formData.append("Gender", data?.Gender || "");
    formData.append("MaritalStatus", data?.MaritalStatus || "");
    formData.append("EmailId", data?.EmailId || "");
    formData.append("authData", JSON.stringify(authData));
    saveProfileData(formData);
  };
  const getProfilePicDetails = (e) => {
    var file = e?.target?.files?.[0];
    setImageFile(file);
    var url = URL.createObjectURL(file);
    setProfilePicUrl(url);
  };
  if (loading)
    return (
      <div className="centerLoader1">
        <Loader />
      </div>
    );
  return (
    <div className="patient_header mt-3 p-5" style={{ ...cardOrientation }}>
      <h4>My Profile</h4>{" "}
      {!editPage && (
        <React.Fragment>
          <PatientProfile data={profileDetails} setEditPage={setEditPage} />
        </React.Fragment>
      )}
      {editPage && (
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3 mt-3">
              <label htmlFor="name" className="form-label">
                Profile Pic
              </label>
              {profilePicUrl && (
                <div>
                  <img
                    onClick={() => {
                      editPage && profileRef?.current?.click();
                    }}
                    style={{
                      width: "150px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    src={profilePicUrl}
                    alt="profile pic"
                  />{" "}
                  <br />{" "}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      profileRef?.current?.click();
                    }}
                  >
                    Change Image
                  </div>
                </div>
              )}
              <input
                style={{ display: "none" }}
                ref={profileRef}
                onChange={(e) => {
                  getProfilePicDetails(e);
                }}
                type="file"
                className="form-control p-2"
                id="name"
                accept=".png"
                placeholder="Enter Your Name"
                name="FileName"
              />
              {errors["FileName"] && (
                <p className="errorText">
                  {errors["FileName"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3 mt-3">
              <label htmlFor="name" className="form-label">
                Full Name*
              </label>
              <input
                {...register("FullName")}
                type="text"
                className="form-control p-2"
                id="name"
                placeholder="Name*"
                name="FullName"
              />
              {errors["FullName"] && (
                <p className="errorText">
                  {errors["FullName"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-2 mt-2">
              <label htmlFor="gender" className="form-label">
                Gender
              </label>
              <select
                name="Gender"
                id="rootField"
                // onChange={handleInput}
                {...register("Gender")}
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="">Gender</option>
                {dashboardData?.["Gender"]?.length &&
                  dashboardData["Gender"].map((item, i) => (
                    <option key={i} value={item?.Value}>
                      {item?.Text}
                    </option>
                  ))}
              </select>
              {errors["Gender"] && (
                <p className="errorText">
                  {errors?.["Gender"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-2 mt-2">
              <label htmlFor="name" className="form-label">
                Marital Status
              </label>
              <select
                name="MaritalStatus"
                id="rootField"
                {...register("MaritalStatus")}
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="">Marital Status </option>
                {dashboardData["MarritalStatus"] &&
                  dashboardData["MarritalStatus"].map((item, i) => (
                    <option key={i} value={item.Value}>
                      {item.Text}
                    </option>
                  ))}
              </select>
              {errors["MaritalStatus"] && (
                <p className="errorText">
                  {errors["MaritalStatus"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3 mt-3">
              <label htmlFor="email" className="form-label">
                Mobile Number*
              </label>
              <input
                disabled
                type="email"
                value={callGet("auth")?.userId || ""}
                className="form-control P-2"
                id="email"
                placeholder="Mobile Number"
                name="MobileNumber"
              />
              {errors["MobileNumber"] && (
                <p className="errorText">
                  {errors["MobileNumber"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3 mt-3">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <input
                {...register("EmailId", {
                  onChange: (e) => {
                    if (!e?.target?.value) {
                      clearErrors("EmailId");
                    }
                  },
                })}
                type="email"
                className="form-control P-2"
                id="email"
                placeholder="Email Id"
                name="EmailId"
              />
              {errors["EmailId"] && (
                <p className="errorText">
                  {errors["EmailId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-12 profilecanclebtn">
            {/* {!editPage && (
              <div className="pt-3 ">
                <button
                  className="btn btn-primary"
                  onClick={() => setEditPage(true)}
                  type="submit"
                  style={{ ...rootButton }}
                >
                  Edit Profile
                </button>
              </div>
            )} */}
            {editPage && (
              <div className="pt-3">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(getFormData)}
                  type="submit"
                  style={{ ...rootButton }}
                >
                  Submit
                </button>
              </div>
            )}
            {editPage && (
              <div className="pt-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    clearErrors("EmailId");
                    clearErrors("FileName");
                    clearErrors("FullName");
                    clearErrors("Gender");
                    clearErrors("MaritalStatus");
                    reset();
                    setEditPage(false);
                  }}
                  type="submit"
                  style={{ ...rootButton }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
