import React, { useEffect, useState } from "react";
import MainIcon from "../../assets/mainIcon/MainIcon";
import RootSelect from "../../assets/util/Root/RootSelect";
import { cardOrientation, rootButton } from "../../assets/util/common/root";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MainModal from "../../assets/modal/MainModal";
import usePost from "../../Hooks/usePost";
import { toast } from "react-hot-toast";
import API from "../../util/API/api";
import { callGet } from "../../common/local";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import ValidateOther from "assets/util/Root/ValidateOther";

const style = {
  position: "absolute",
  left: "37.25%",
  right: "17.19%",
  top: "20.93%",
  bottom: "34.67%",
  background: " #FFFFFF",
  opacity: "0.9",
  border: "1px solid #007DC6",
  boxShadow: "1px 1px 20px 1px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
};
const schema = yup
  .object()
  .shape({
    UHID: yup.string().required("UHID is required"),
  })
  .when((values, schema) => {
    if (values?.UHID === "other") {
      return schema.shape({
        typeUHID: yup
          .string()
          .min(9, "Enter a valid UHID")
          .max(9, "Enter a valid UHID")
          .required("UHID is required"),
      });
    }
  });
const Immunisation = () => {
  const [open, setOpen] = React.useState(false);
  const [loading1, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, getData, setApi] = usePost();
  const [loadingImu, getImu, setImuApi] = usePost();
  const [loadingTestImu, getTestImu, setTestApi] = usePost();
  const [UHIDList, setUHIDList] = useState("");
  const [uhidData, setUhidData] = useState("");
  const [currUHID, setCurrUHID] = useState("");
  const [ImuRec, setImuRecord] = useState([]);
  const [otp, setOtp] = useState("");
  const [validate, setValidate] = useState(false);
  const [errorOTP, setErrorOTP] = useState(false);
  const [otpResponse, setOtpResponse] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      UHID: "",
      typeUHID: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (getImu) {
      if (getImu.IsSuccessful) {
        setImuRecord(getImu?.Data);
        handleOpen();
      } else {
        toast.error(getImu?.FriendlyMessage);
      }
    }
  }, [getImu]);
  const getReportData = (data) => {
    setImuApi(
      API.getimmunizationcharturl({ ...callGet("auth"), UHID: data?.UHID })
    );
  };
  useEffect(() => {
    setTestApi({
      url: "api/patientportal/getuhidinfo",
      body: { ...callGet("auth") },
    });
    if (getData) {
      if (getData?.IsSuccessful) {
        setUHIDList(
          getData?.Data?.map((e) => ({
            name: `${e?.UHID} - ${e?.Name}`,
            value: e?.UHID,
          }))
        );
      } else {
        toast.error(getData?.FriendlyMessage);
      }
    }
  }, [getData]);

  useEffect(() => {
    window.scroll(0, 0);
    var body = { ...callGet("auth") };
    setApi(API.getuhidlistbymobile(body));
  }, []);
  const getOTP = async (data) => {
    try {
      setLoading(true);
      const otpResp = await postAPIData({ ...API.MyReportGetOTP(data) });
      if (otpResp?.data?.IsSuccessful) {
        setOtpResponse(otpResp?.data?.Data?.[0]);
        toast.success(otpResp?.data?.FriendlyMessage);
        setValidate(true);
      } else {
        toast.error(otpResp?.data?.FriendlyMessage || "Something went wrong");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const matchOTP = async (data, obj) => {
    try {
      setLoading(true);
      const matchOTPResp = await postAPIData({ ...API.MatchMyReportOTP(data) });
      if (matchOTPResp?.data?.IsSuccessful) {
        toast.success(matchOTPResp?.data?.FriendlyMessage);
        obj.UHID = obj?.typeUHID;
        getReportData(obj);
        setOtp("");
        setErrorOTP(false);
      } else {
        toast.error(
          matchOTPResp?.data?.FriendlyMessage || "Something went wrong"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getFormData = async (data) => {
    if (uhidData === "other" && (!otp || otp?.length > 4 || otp?.length < 4)) {
      setErrorOTP(true);
      return;
    }
    if (uhidData === "other") {
      const authData = callGet("auth");
      let obj = {
        ...authData,
        UHID: data?.typeUHID,
        OTP: otp,
        OTPCode: otpResponse?.OTPCode,
      };
      await matchOTP(obj, data);
    } else {
      getReportData(data);
    }
  };

  useEffect(() => {
    setValue("typeUHID", "");
    setCurrUHID("");
    setOtp("");
    setImuRecord([]);
  }, [uhidData]);

  const getOTPData = (data) => {
    const authData = callGet("auth");
    let obj = { ...authData, UHID: data?.typeUHID };
    getOTP(obj);
  };
  return (
    <div className="--header container-fluid p-4 mt-2 " style={cardOrientation}>
      <h4>Immunisation Record</h4>
      <MainIcon top={720} right={50} width={350} />
      <div className="row">
        <div className="col-md-8 p-2">
          <RootSelect
            bootstrap={{
              divCss: "col-md-6 p-2",
              selectCss: "form-control p-2 rootselect",
            }}
            placeholder={"Select UHID or Name*"}
            options={[...UHIDList, { name: "OTHERS", value: "other" }]}
            name={"UHID"}
            setSelectedOption={setUhidData}
            register={register}
            error={errors}
          />
          {/* {uhidData === "other" && (
            <div className="col-md-4 p-2">
              <input
                pattern="\d{9}"
                maxLength={9}
                {...register("typeUHID", {
                  onChange: (e) => {
                    if (e?.target?.value?.length > 9) {
                      setValue("typeUHID", currUHID);
                      return;
                    }
                    setCurrUHID(e?.target?.value);
                  },
                })}
                type="number"
                className="form-control p-2"
                id="email"
                placeholder="Enter UHID*"
                name="typeUHID"
              />
              {errors["typeUHID"] && (
                <p className="errorText">
                  {errors["typeUHID"]?.message?.toString()}
                </p>
              )}
            </div>
          )} */}
        </div>
        {uhidData === "other" && (
          <ValidateOther
            register={register}
            setValue={setValue}
            setCurrUHID={setCurrUHID}
            currUHID={currUHID}
            errors={errors}
            loading={loading1}
            otp={otp}
            setOtp={setOtp}
            handleSubmit={handleSubmit}
            getOTPData={getOTPData}
            errorOTP={errorOTP}
            name={"typeUHID"}
          />
        )}
        <div className="mt-3">
          {/* {uhidData === "other" && (
            <React.Fragment>
              <button
                className="btn btn-primary"
                onClick={handleSubmit(getOTPData)}
                type="submit"
                style={{ ...rootButton }}
                disabled={loading1}
              >
                Get OTP
              </button>
              <div style={{ margin: "10px 0" }}>
                <input
                  value={otp}
                  onChange={(e) => {
                    if (e?.target?.value?.length > 4) return;
                    setOtp(e?.target?.value);
                  }}
                  type="number"
                  className="form-control p-2"
                  id="email"
                  placeholder="Enter OTP*"
                  name="otp"
                />
                {errorOTP && (
                  <p className="errorText">
                    {!otp?.length
                      ? "Enter OTP"
                      : otp?.length < 4 || otp?.length > 4
                      ? "Enter a valid OTP"
                      : ""}
                  </p>
                )}
              </div>
            </React.Fragment>
          )} */}
          <button
            className="btn btn-primary"
            onClick={handleSubmit(getFormData)}
            type="submit"
            style={{ ...rootButton }}
            disabled={
              loading ||
              loading1 ||
              (uhidData === "other" && !validate) ||
              ImuRec?.[0]?.Url
            }
          >
            View Details
          </button>
          {ImuRec?.[0]?.Url && (
            <a href={ImuRec?.[0]?.Url || "#"} target="_blank">
              <button
                className="btn btn-primary"
                type="submit"
                style={{ ...rootButton, marginLeft: "10px" }}
                disabled={
                  loading || loading1 || (uhidData === "other" && !validate)
                }
              >
                View Record
              </button>
            </a>
          )}
          <MainModal
            open={open}
            handleClose={handleClose}
            Component={Component}
            props={{ ImuRec }}
          />
        </div>
        {(loading || loading1 || loadingImu) && (
          <div className="loader-background">
            <div className="centerLoader1">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function Component({ handleClose, props }) {
  const { ImuRec } = props;
  const authData = callGet("auth");
  return (
    <Box sx={style} className="p-5 text-center outer_poptext">
      {ImuRec?.[0]?.Url ? (
        <React.Fragment>
          <MainIcon top={140} right={20} width={180} />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 className="mt-3">
              {" "}
              <b style={{ color: "#007DC6" }}>
                {authData?.hospitalName || "MARENGO ASIA HOSPITAL"}
              </b>
            </h5>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3, mb: 3 }}>
            Got the Data
          </Typography>
          <button
            type="submit"
            onClick={handleClose}
            style={{ ...rootButton, padding: "10px 60px", cursor: "pointer" }}
          >
            Ok
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MainIcon top={140} right={20} width={85} />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 className="mt-3">
              {" "}
              <b style={{ color: "#007DC6" }}>
                {" "}
                {authData?.hospitalName || "MARENGO ASIA HOSPITAL"}
              </b>
            </h5>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3, mb: 3 }}>
            No Record (s) found for this patients
          </Typography>
          <button
            type="submit"
            onClick={handleClose}
            style={{ ...rootButton, padding: "10px 60px", marginTop: "10px" }}
          >
            Ok
          </button>
        </React.Fragment>
      )}
    </Box>
  );
}
export default Immunisation;
