import React, { useEffect, useLayoutEffect, useState } from "react";
import "./MyAppointment.css";
import { cardOrientation } from "../../assets/util/common/root";
import MyAppointmentDetails from "./MyAppointmentDetails/MyAppointmentDetails";
import API from "../../util/API/api";
import { callGet } from "../../common/local";
import RootSearch from "../../assets/util/Root/RootSearch/RootSearch";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "Action/action";
const MyAppointment = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [appointData, setAppointData] = useState([]);
  const [FriendlyMessage, setFriendlyMessage] = useState("");
  const [singleAppoint, setSingleAppoint] = useState("");

  function handleSingleAppoint(appointId) {
    setSingleAppoint(
      appointData.find((data) => data?.AppointmentId == appointId)
    );
    setValue(2);
  }

  var componentsType = {
    1: (
      <MyAppointmentChild
        loading={loading}
        appointData={appointData}
        FriendlyMessage={FriendlyMessage}
        setValue={setValue}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    ),
    2: (
      <MyAppointmentDetails
        loading={loading}
        setLoading={setLoading}
        singleAppoint={singleAppoint}
        setValue={setValue}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    ),
  };
  const setData = (getData) => {
    if (getData?.IsSuccessful) {
      setFriendlyMessage(getData?.FriendlyMessage);
      setAppointData(getData?.Data);
    } else {
      setFriendlyMessage(getData?.FriendlyMessage);
    }
  };
  const getAppointmentHistory = async () => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API.getappointmenthistory({ ...callGet("auth") }),
      });
      setData(resp?.data);
    } catch (err) {
      console.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    let appId = searchParams?.get("id");
    dispatch({ type: "CLEAR_TAKE_APPOINT" });
    dispatch({ type: "DELETE_PATIENT_APPOINT" });
    if (!appId) {
      setValue(1);
      return;
    } else {
      setValue(2);
    }
    (async () => {
      if (!appointData?.length) {
        await getAppointmentHistory();
      }
      handleSingleAppoint(appId);
    })();
  }, [searchParams?.get("id"), appointData?.length]);
  useLayoutEffect(() => {
    getAppointmentHistory();
    window.scroll(0, 0);
  }, []);

  return <React.Fragment>{componentsType?.[value]}</React.Fragment>;
};

function MyAppointmentChild({
  loading,
  appointData,
  FriendlyMessage,
  setAppointDetails,
  searchParams,
  setSearchParams = () => {},
}) {
  const paraStyle = { fontSize: ".9rem", fontWeight: "500" };
  const [searchData, setsearchData] = useState("");
  const [appointmentData, setApppointmentData] = useState([]);
  const dispatch = useDispatch();
  const handleFilter = () => {
    const newFilter = appointData?.filter((value) => {
      let text = searchData?.trim()?.toLocaleLowerCase();
      const filterData =
        value?.DoctorName?.toLowerCase()?.includes(text) ||
        value?.FirstName?.toLowerCase()?.includes(text) ||
        value?.UHID?.toLowerCase()?.includes(text);
      return filterData;
    });
    setApppointmentData(newFilter);
  };
  useEffect(() => {
    setApppointmentData(appointData);
  }, [appointData]);
  useEffect(() => {
    if (searchData) {
      handleFilter();
    } else {
      setApppointmentData(appointData);
    }
  }, [searchData]);

  return (
    <React.Fragment>
      <div className="patient_header mt-3 p-5" style={{ ...cardOrientation }}>
        <h4>My Appointments</h4>
        <div className="pt-2" style={{ width: "50%" }}>
          <RootSearch
            placeholder={"Search By Doctor/Patient Name/UHID"}
            setsearchData={setsearchData}
            searchData={searchData}
            onChange={(e) => {
              setsearchData(e?.target?.value);
            }}
            disabled={loading || !appointData?.length}
            borderRadius={{ outLine: "1px solid red" }}
          />
        </div>
        <div
          className="container-fluid"
          style={{
            ...cardOrientation,
            backgroundColor: "transparent",
            minHeight: "50vh",
            maxHeight: "120vh",
            overflowY: "auto",
          }}
        >
          {loading ? (
            <div className="centerLoader1">
              <Loader />
            </div>
          ) : (
            <div className="row px-3 mt-4" style={{ cursor: "pointer" }}>
              {!FriendlyMessage ? (
                appointmentData?.length ? (
                  appointmentData?.map((data, index) => (
                    <div className="col-md-4" key={index}>
                      <div
                        className="card pt-2 mb-4 px-3 "
                        style={{
                          border: "1px solid #007DC6",
                          height: "200px",
                          background: "#FFFFFF",
                        }}
                        onClick={() => {
                          searchParams.set("id", data?.AppointmentId);
                          searchParams.set("doctor_id", data?.DoctorId);
                          dispatch(actions?.A_appointmentDetails(data));
                          setSearchParams(searchParams);
                        }}
                      >
                        <div className="card-body">
                          <div
                            style={{
                              color: "#007DC6",
                              ...paraStyle,
                              fontSize: "15px",
                            }}
                          >
                            {data?.DoctorName}
                          </div>
                          <div
                            style={{
                              color: "#FF6F0F",
                              fontSize: "17px",
                              margin: "7px 0",
                            }}
                          >
                            UHID {data?.UHID}
                          </div>
                          <div className="appoint-card-details">
                            Patient: {data?.PatientName}
                          </div>
                          <div className="appoint-card-details">
                            {data?.AppointmentOn}
                          </div>
                          <div className="appoint-card-details">
                            {data?.AppointmentTypeName}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center">No Data Found</p>
                )
              ) : (
                <p className="text-center">{FriendlyMessage}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyAppointment;
