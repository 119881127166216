import React, { useEffect, useState } from "react";
import "./PreventHealth.css";
import preventimg from "assets/image/Group.png";
import preventIcon from "assets/image/preventIcon.png";
import { useDispatch, useSelector } from "react-redux";
import API from "util/API/api";
import { callGet } from "common/local";
import MainModal from "assets/modal/MainModal";
import MainIcon from "assets/mainIcon/MainIcon";
import { cardOrientation } from "assets/util/common/root";
import { postThunk } from "Action/middleware/thunk";
import Loader from "Pages/Loader";
import { Icon } from "@iconify/react";

const style = {
  // boxSizing:"border-box",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,

  // border: "2px solid #000",
  minHeight: 450,
  maxHeight: 600,
  background: " #FFFFFF",
  opacity: "0.9",
  border: "1px solid #007DC6",
  boxShadow: "1px 1px 20px 1px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  padding: "50px",
};

const PreventiveHealth = () => {
  const dispatch = useDispatch();
  const checkupList = useSelector(
    (state) => state.R_getpreventivehealthcheckups
  );
  const searchText = useSelector((state) => state?.R_searchText);

  useEffect(() => {
    if (searchText?.hospitalId) {
      var body = {
        ...callGet("auth"),
        FacilityId: searchText?.hospitalId || "2",
      };
      dispatch(postThunk(API.A_getpreventivehealthcheckups(body)));
    }
  }, [searchText?.hospitalId]);
  return (
    <div
      className="container-fluid"
      style={{
        ...cardOrientation,
        backgroundColor: "transparent",
        minHeight: "50vh",
        maxHeight: "160vh",
        overflowY: "scroll",
      }}
    >
      {checkupList?.loading ? (
        <div className="centerLoader1">
          <Loader />
        </div>
      ) : (
        <div className="row py-4">
          {checkupList?.data?.length ? (
            checkupList?.data?.map((list, index) => (
              <Card data={list} index={index} />
            ))
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      )}
    </div>
  );
};

function Card({ data, index }) {
  const [open, setOpen] = useState(false);
  const handleclose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [modalDataIndex, setModalDataIndex] = useState(-1);

  function handleModalData(index) {
    handleOpen();
    setModalDataIndex(index);
  }
  return (
    <div className="col-md-4 col-sm-6 py-3">
      <div className="card " id="prevent_check">
        <div className="card-title  px-5" style={{ zIndex: "999" }}>
          <img
            style={{
              width: "70px",
              borderRadius: "50%",
              marginTop: "45px",
              marginBottom: "20px",
            }}
            draggable="false"
            src={preventimg}
            alt=""
          />
          <h6
            style={{
              color: "#1A2352",
              fontWeight: "700",
              fontSize: "0.9rem",
              marginBottom: "0px",
            }}
          >
            {data?.Title}
          </h6>
        </div>
        <div className="card-body px-5 mb-0 d-flex justify-content-between align-items-center">
          <div>
            <h6
              style={{
                color: "#FF6F0F",
                fontWeight: "700",
                marginBottom: "0px",
              }}
            >
              &#x20b9;{data?.Amount}
            </h6>
            <p style={{ opacity: "0.7" }}>Package Amount</p>
          </div>
          <div
            onClick={() => handleModalData(index)}
            style={{ cursor: "pointer" }}
          >
            <img src={preventIcon} draggable="false" alt="icon" />
          </div>
        </div>
      </div>

      <MainModal
        open={open}
        handleClose={handleclose}
        Component={ListDetail}
        props={{ modalDataIndex, handleclose }}
      />
    </div>
  );
}

function ListDetail({ props }) {
  const { modalDataIndex } = props;
  const checkupList = useSelector((state) => {
    let data;
    if (state.R_getpreventivehealthcheckups?.data?.length) {
      data = state.R_getpreventivehealthcheckups?.data.find(
        (e, i) => i === modalDataIndex
      );
    }
    return data;
  });

  return (
    <div style={style}>
      <div
        onClick={() => {
          props?.handleclose();
        }}
        className="cross-icon-confirm"
      >
        <Icon fontSize={"20px"} icon="maki:cross" color="#0d6efd" />
      </div>
      <MainIcon bottom={15} right={20} width={100} />
      <h4 className="text-cente whole-body">{checkupList?.Title}</h4>
      <p>
        <span id="modal_rupe">₹{checkupList?.Amount}</span> Package Amount
      </p>
      <ul type="disc" className="preventive-modal">
        {checkupList?.CheckUpList?.length
          ? checkupList?.CheckUpList.map((e, index) => (
              <li key={index}>{e?.Title}</li>
            ))
          : ""}
      </ul>
    </div>
  );
}

// data?.Icon?getImg(data?.Icon):
export default PreventiveHealth;
