import React, { useEffect, useState } from "react";
import MainIcon from "assets/mainIcon/MainIcon";
import MainModal from "assets/modal/MainModal";
import Box from "@mui/material/Box";
import { BiRotateRight } from "react-icons/bi";
import { cardOrientation, rootButton } from "assets/util/common/root";
import "./PatientPayment.css";
import API from "util/API/api";
import { useDispatch, useSelector } from "react-redux";
import { callGet } from "common/local";
import { SERVER } from "server/server";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { postAPIData } from "Hooks/Services";
import { toast } from "react-hot-toast";
import { Icon } from "@iconify/react";
import AllCoupons from "./AllCoupons";
import actions from "Action/action";
import Loader from "Pages/Loader";
import { anteMeridiemFormat, getLocalFormat } from "common/days";
export const secretKey = "MARENGO_PATIENT_PORTAL";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  height: 400,
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const PatientPayment = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  var myDataCookie = searchParams?.get("payment");
  const [showCoupons, setShowCoupons] = useState(false);
  const [validateCouponData, setValidateCouponData] = useState({});
  const [couponsData, setCouponsData] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState({
    CouponCode: "",
    CouponId: "",
  });
  const [loading, setLoading] = useState(false);
  const [gatewayLoading, setGatewayLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [advLoading, setAdvLoading] = useState(false);
  const paymentDetails = useSelector((state) => state.R_getdoctorfees);
  const saveDetails = useSelector((state) => state.R_savePatientAppoint);
  const AppointConfirmation = useSelector((state) => state.R_takeappointment);
  const appDetails = useSelector((state) => state?.R_AppointmentDetails);
  const authData = callGet("auth");
  const [merchantData, setMerchantData] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    (async () => {
      myDataCookie = myDataCookie = myDataCookie?.trim()?.replaceAll(" ", "+");
      if (myDataCookie) {
        const secretKey = "MARENGO_PATIENT_PORTAL";
        const bytes = CryptoJS.AES.decrypt(myDataCookie, secretKey);
        let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        decryptedData = JSON.parse(decryptedData);
        if (decryptedData?.status !== "success") {
          toast.error("Payment unsuccessful");
          return;
        }
        const payload = {
          Amount:
            paymentDetails?.data?.length > 1
              ? paymentDetails?.data.reduce(
                  (a, b) => a?.ServiceAmount + b?.ServiceAmount
                )
              : paymentDetails?.data[0]?.ServiceAmount,
          paymentData: paymentDetails?.data?.map((e) => ({
            ServiceId: e?.ServiceId,
            ServiceType: e?.ServiceType,
            Amount: e?.ServiceAmount,
          })),
          AdvanceTypeId: "0" || AppointConfirmation?.data?.AppointmentTypeID,
          AppointmentDate: AppointConfirmation?.data?.AppointmentDate,
          AppointmentId: AppointConfirmation?.data?.AppointmentId || "0",
          CouponId: AppointConfirmation?.data?.CouponId || "",
          DiscountAmount: AppointConfirmation?.data?.DiscountAmount || "",
          DoctorId: AppointConfirmation?.data?.DoctorId,
          EmailId: AppointConfirmation?.data?.email,
          HospitalId: AppointConfirmation?.data?.FacilityId,
          IsCancelledByUser: false,
          MobileNumber: AppointConfirmation?.hash?.mobile,
          Mode: decryptedData?.mode,
          PaymentResponse: decryptedData,
          RescheduleTimeSlot: "",
          Status: decryptedData?.status === "success",
          TimeSlot: AppointConfirmation?.data?.TimeSlot,
          TimeSlotId: AppointConfirmation?.data?.TimeSlotId,
          TransactionId: decryptedData?.txnid,
          UHID: AppointConfirmation?.data?.UHID,
          additionalCharges: decryptedData?.additionalCharges,
          MihPayId: decryptedData?.mihpayid,
          txnId: authData?.TxnID,
          authData,
        };
        payAdvance(payload);
      }
    })();
  }, [params, myDataCookie]);

  const callPayment = async () => {
    try {
      setPaymentLoading(true);

      const takeAppPayLoad = {
        ...authData,
        DoctorId:
          saveDetails?.takeAppointment?.DoctorId || appDetails?.data?.DoctorId,
        FacilityId:
          paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
            ?.HospitalId || appDetails?.data?.HospitalId,
        UHID: paymentDetails?.patientDetails?.UHID || appDetails?.data?.UHID,
        TimeSlot: saveDetails?.slot?.TimeSlot || appDetails?.data?.TimeSlot,
        TimeSlotId: saveDetails?.slot?.SlotId || appDetails?.data?.TimeSlotId,
        AppointmentDate:
          saveDetails?.takeAppointment?.Date ||
          appDetails?.data?.AppointmentDate,
        Interval:
          saveDetails?.slot?.Interval || appDetails?.data?.Interval || "",
        IsPayLaterAppointment: false,
        RescheduleTimeSlotId:
          saveDetails?.reScheduleSlot?.SlotId ||
          appDetails?.data?.RescheduleTimeSlotId,
        RescheduleInterval: appDetails?.data?.RescheduleInterval || "",
        RescheduleTimeSlot: appDetails?.data?.RescheduleTimeSlotId,
        AppointmentTypeCode:
          saveDetails?.takeAppointment?.AppointmentTypeCode ||
          appDetails?.data?.AppointmentTypeID === 2
            ? "OS"
            : "TS",
      };
      const resp =
        !appDetails?.data?.AppointmentId &&
        (await postAPIData({
          ...API.A_takeappointment(takeAppPayLoad),
        }));
      if (appDetails?.data?.AppointmentId || resp?.data?.IsSuccessful) {
        const payload = {
          ...authData,
          // amount: paymentDetails?.data?.[0]?.ServiceAmount,
          patientName:
            paymentDetails?.patientDetails?.patientName ||
            appDetails?.data?.PatientName,
          patientEmail:
            paymentDetails?.patientDetails?.email ||
            appDetails?.data?.PatientEmail,
          mobileNumber:
            paymentDetails?.patientDetails?.userId ||
            appDetails?.data?.Phone ||
            "",
          UHID: paymentDetails?.patientDetails?.UHID || appDetails?.data?.UHID,
          txnId: authData?.TxnID,
          HospitalId:
            paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
              ?.HospitalId || appDetails?.data?.HospitalId,
          AppointmentId:
            resp?.data?.Data?.[0]?.AppointmentId ||
            appDetails?.data?.AppointmentId,
          DoctorId:
            saveDetails?.takeAppointment?.DoctorId ||
            appDetails?.data?.DoctorId,
          AppointmentDate:
            saveDetails?.takeAppointment?.Date ||
            appDetails?.data?.AppointmentDate,
          TimeSlot: saveDetails?.slot?.TimeSlot || appDetails?.data?.TimeSlot,
          // ServiceId: paymentDetails?.data?.[0]?.ServiceId,
          // ServiceType: paymentDetails?.data?.[0]?.ServiceType,
          CouponId: validateCouponData?.CouponId || "",
          DiscountAmount: validateCouponData?.DiscountAmount || "",
          paymentData: paymentDetails?.data?.map((e) => ({
            ServiceId: e?.ServiceId,
            ServiceType: e?.ServiceType,
            amount: e?.ServiceAmount,
          })),
          total_amount:
            paymentDetails?.data?.length > 1
              ? paymentDetails?.data.reduce(
                  (a, b) => a?.ServiceAmount + b?.ServiceAmount
                )
              : paymentDetails?.data[0]?.ServiceAmount,
        };
        const seqResp = await postAPIData({
          ...API.generateSequence(payload),
        });
        if (seqResp?.data?.IsSuccessful) {
          const paymentPayload = {
            DoctorId:
              saveDetails?.takeAppointment?.DoctorId ||
              appDetails?.data?.DoctorId,
            FacilityId:
              paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
                ?.HospitalId || appDetails?.data?.HospitalId,
            UHID:
              paymentDetails?.patientDetails?.UHID || appDetails?.data?.UHID,
            AppointmentDate:
              saveDetails?.takeAppointment?.Date ||
              appDetails?.data?.AppointmentDate,
            TimeSlot: saveDetails?.slot?.TimeSlot || appDetails?.data?.TimeSlot,
            TimeSlotId:
              saveDetails?.slot?.SlotId || appDetails?.data?.TimeSlotId,
            AppointmentTypeID:
              saveDetails?.takeAppointment?.AppointmentTypeID ||
              appDetails?.data?.AppointmentTypeID,
            AgreeWithTerms: "true",
            ClientId: authData?.ClientId,
            ClientToken: authData?.ClientToken,
            userId: authData?.userId,
            name:
              paymentDetails?.patientDetails?.patientName ||
              appDetails?.data?.PatientName,
            email:
              paymentDetails?.patientDetails?.email ||
              appDetails?.data?.Email ||
              "",
            phone:
              paymentDetails?.patientDetails?.userId ||
              appDetails?.data?.Phone ||
              "",
            MerchantKey: merchantData?.MerchantKey || "",
            Salt: merchantData?.Salt || "",
            amount:
              paymentDetails?.data?.length > 1
                ? paymentDetails?.data.reduce(
                    (a, b) => a?.ServiceAmount + b?.ServiceAmount
                  )
                : paymentDetails?.data[0]?.ServiceAmount,
          };
          dispatch({
            type: "GET_TAKE_APPOINT",
            payload: {
              ...paymentPayload,
              AppointmentId:
                resp?.data?.Data?.[0]?.AppointmentId ||
                appDetails?.data?.AppointmentId ||
                "",
              DiscountAmount: validateCouponData?.DiscountAmount,
              CouponId: validateCouponData?.CouponId,
            },
          });
          const paymentResp = await axios.post(
            `${SERVER}/api/patientportal/payment/payumoney`,
            {
              ...paymentPayload,
              amount:
                paymentPayload?.amount -
                (validateCouponData?.DiscountAmount || 0),
            }
          );
          dispatch({ type: "SET_HASH", payload: paymentResp?.data?.info });
          let htmlBody = `<html>
                  <body>
                  <form name="payment_post" id="payu_form" action="${paymentResp?.data.info.payu_url}" method="post">
                  <input type='hidden' name='firstname' value="${paymentResp?.data.info.first_name}"/>
                  <input type='hidden' name='email' value="${paymentResp?.data.info.email}"/>
                  <input type='hidden' name='phone' value="${paymentResp?.data.info.mobile}"/>
                  <input type='hidden' name='surl' value="${paymentResp?.data.info.callback_url}"/>
                  <input type='hidden' name='curl' value="${paymentResp?.data.info.payu_cancel_url}"/>
                  <input type='hidden' name='furl' value="${paymentResp?.data.info.payu_fail_url}"/>
                  <input type='hidden' name='key' value="${paymentResp?.data.info.payu_merchant_key}"/>
                  <input type='hidden' name='hash' value="${paymentResp?.data.info.payu_sha_token}"/>
                  <input type='hidden' name='txnid' value="${paymentResp?.data.info.txnId}"/>
                  <input type='hidden' name='productinfo' value="${paymentResp?.data.info.plane_name}"/>
                  <input type='hidden' name='amount' value="${paymentResp.data.info.amount}"/>
                  <input type='hidden' name='service_provider' value="${paymentResp.data.info.service_provider}"/>
                  </form>
                  <script type='text/javascript'>
                       document.getElementById("payu_form").submit();
                  </script>
                  </body>
              </html>`;

          const winURL = URL.createObjectURL(
            new Blob([htmlBody], { type: "text/html" })
          );
          window.location.href = winURL;
        } else {
          throw seqResp?.data;
        }
      } else {
        throw resp?.data;
      }
    } catch (err) {
      toast.error(err.FriendlyMessage || "Something went wrong !!");
    } finally {
      setPaymentLoading(false);
    }
  };

  const payAdvance = async (data) => {
    try {
      setAdvLoading(true);
      const resp = await postAPIData({
        ...API.payAdvance(data),
      });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      Cookies.remove("myData");
      searchParams.delete("payment");
      setSearchParams(searchParams);
      dispatch({ type: "CLEAR_TAKE_APPOINT" });
      dispatch({ type: "DELETE_PATIENT_APPOINT" });
      dispatch({ type: "REMOVE_DATA" });
      navigate("/dashboard", { replace: true });
      navigate(
        `/dashboard/redirects/payment?type=appointment&success=true&id=${data?.AppointmentId}`
      );
    } catch (err) {
      console.error(err);
      toast.error(err.FriendlyMessage || "Something went wrong");
    } finally {
      setAdvLoading(false);
    }
  };
  function handleTakeAnAppoint() {
    handleOpen();
    handleClose();
  }
  const getCoupons = async (data, availableData) => {
    try {
      setCouponLoading(true);
      const availableResp = await postAPIData({
        ...API.isCouponAvailable({ ...availableData }),
      });
      if (
        !availableResp?.data?.IsSuccessful ||
        !availableResp?.data?.Data?.[0]?.IsCouponAvailable
      ) {
        throw availableResp?.data;
      }
      const resp = await postAPIData({
        ...API.getCoupons({
          ...data,
          SpecialityId: availableData?.SpecialityId,
        }),
      });
      if (resp?.data?.IsSuccessful) {
        setCouponsData(resp?.data?.Data);
        dispatch(
          actions.A_getAllCoupons({
            couponData: resp?.data?.Data,
          })
        );
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCouponLoading(false);
    }
  };
  const applyCoupon = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API.validateCoupon({ ...data }),
      });
      if (resp?.data?.IsSuccessful) {
        setSelectedCoupon({
          CouponCode: "",
          CouponId: "",
        });
        if (
          !resp?.data?.Data?.[0]?.IsValidCoupon &&
          resp?.data?.Data?.[0]?.Message
        ) {
          toast.error(resp?.data?.Data?.[0]?.Message);
          throw resp?.data?.Data?.[0]?.Message;
        }
        toast?.success(resp?.data?.Data?.[0]?.CouponDescription);
        setValidateCouponData({ ...resp?.data?.Data?.[0], ...data });
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCoupon?.CouponId && selectedCoupon?.CouponCode) {
      const payload = {
        ...authData,
        HospitalId:
          saveDetails?.doctorDetail?.HospitalId || appDetails?.data?.HospitalId,
        UHID: paymentDetails?.patientDetails?.UHID || appDetails?.data?.UHID,
        PaymentServices: [
          {
            Amount:
              paymentDetails?.data?.length > 1
                ? paymentDetails?.data.reduce(
                    (a, b) => a?.ServiceAmount + b?.ServiceAmount
                  )
                : paymentDetails?.data[0]?.ServiceAmount,
            ServiceId: paymentDetails?.data?.[0]?.ServiceId,
            ServiceType: paymentDetails?.data?.[0]?.ServiceType,
          },
        ],
        CouponCode: selectedCoupon?.CouponCode,
        CouponId: selectedCoupon?.CouponId,
        DoctorId:
          saveDetails?.doctorDetail?.DoctorId || appDetails?.data?.DoctorId,
      };
      applyCoupon(payload);
    }
  }, [
    selectedCoupon?.CouponId,
    selectedCoupon?.CouponCode,
    paymentDetails?.data,
    saveDetails?.doctorDetail?.HospitalId,
    saveDetails?.doctorDetail?.DoctorId,
    paymentDetails?.patientDetails?.UHID,
    appDetails?.data?.DoctorId,
    appDetails?.data?.HospitalId,
    appDetails?.data?.UHID,
  ]);
  useEffect(() => {
    getCoupons(
      {
        ...authData,
        FacilityId:
          paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
            ?.HospitalId || appDetails?.data?.HospitalId,
        UHID: paymentDetails?.patientDetails?.UHID || appDetails?.data?.UHID,
      },
      {
        ...authData,
        SpecialityId:
          saveDetails?.doctorDetail?.SpecialisationId ||
          appDetails?.data?.SpecialityId,
        DoctorId:
          saveDetails?.doctorDetail?.DoctorId || appDetails?.data?.DoctorId,
      }
    );
  }, [
    paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0],
    paymentDetails?.patientDetails?.UHID,
    saveDetails?.doctorDetail?.SpecialisationId,
    saveDetails?.doctorDetail?.DoctorId,
    appDetails?.data?.HospitalId,
    appDetails?.data?.UHID,
    appDetails?.data?.SpecialityId,
    appDetails?.data?.DoctorId,
  ]);
  const gateWayDetails = async (data) => {
    try {
      setGatewayLoading(true);
      const resp = await postAPIData({
        ...API.getPaymentGetwayDetails({
          FacilityId: data,
          ...authData,
        }),
      });
      if (resp?.data?.IsSuccessful) {
        setMerchantData(resp?.data?.Data);
      } else {
        throw resp?.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setGatewayLoading(false);
    }
  };
  useEffect(() => {
    if (
      paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
        ?.HospitalId ||
      appDetails?.data?.HospitalId
    ) {
      gateWayDetails(
        paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
          ?.HospitalId || appDetails?.data?.HospitalId
      );
    }
  }, [
    paymentDetails?.patientDetails?.doctorDetails?.Facilities?.[0]
      ?.HospitalId || appDetails?.data?.HospitalId,
  ]);
  // console.log(appDetails, "----------------->sdfghjkl;");
  // console.log(paymentDetails, "--------------->");
  // console.log(appDetails?.data?.PatientName);
  // console.log(saveDetails, "--------->");
  // console.log(authData);
  if (showCoupons) {
    return (
      <AllCoupons
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        showCoupons={showCoupons}
        setShowCoupons={setShowCoupons}
        AllCoupponsData={couponsData}
      />
    );
  }
  return (
    <div
      className="container-fluid p-5 mt-2 payment-summary"
      style={{ ...cardOrientation }}
    >
      {(loading ||
        advLoading ||
        paymentDetails?.loading ||
        paymentLoading ||
        gatewayLoading ||
        couponLoading) && (
        <div className="centerLoader2">
          <Loader />
        </div>
      )}
      <MainIcon bottom={0} right={20} width={300} />
      <div className="pateinet-detail mt-3">
        <h4>Patient Details</h4>
        <table className="mt-3" style={{ width: "400px" }}>
          <tbody>
            <tr className="mb-3" style={{ color: "orange" }}>
              <td>Name:</td>
              <td>UHID:</td>
              {/* <td>Age</td> */}
            </tr>
            <tr>
              <td>
                {paymentDetails?.patientDetails?.patientName ||
                  appDetails?.data?.PatientName}
              </td>
              <td>
                {paymentDetails?.patientDetails?.UHID || appDetails?.data?.UHID}
              </td>
              {/* <td>30</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pateinet-detail mt-3">
        <h4>Appointment Details</h4>
        <table className="mt-3" style={{ width: "400px" }}>
          <tbody>
            <tr className="mb-3" style={{ color: "orange" }}>
              <td>Name:</td>
              <td>Appointment Date & Time:</td>
              {/* <td>Age</td> */}
            </tr>
            <tr>
              <td>
                {saveDetails?.doctorDetail?.DoctorName ||
                  appDetails?.data?.DoctorName ||
                  ""}
              </td>
              <td>
                {getLocalFormat(
                  saveDetails?.takeAppointment?.Date ||
                    appDetails?.data?.AppointmentDate ||
                    ""
                )}
                ,{" "}
                {anteMeridiemFormat(
                  saveDetails?.slot?.TimeSlot ||
                    appDetails?.data?.TimeSlot ||
                    ""
                )}
              </td>
              {/* <td>30</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row mt-3 px-3">
        <div className="col-md-8">
          <div className="card  p-4 mt-4 mb-4" style={paymentSummaryStyle}>
            <h5>
              <b>Payment Summary</b>
            </h5>
            <div className="card-collection  mt-1">
              {paymentDetails?.data?.length
                ? paymentDetails?.data?.map((e, i) => (
                    <div key={i} className="d-flex justify-content-between">
                      <p>{e?.ServiceName}</p>
                      <p>₹{e?.ServiceAmount}</p>
                    </div>
                  ))
                : "Payment details not found"}
            </div>
            {/* <hr /> */}

            {paymentDetails?.data?.length > 0 && (
              <div className="net-payable d-flex justify-content-between ">
                <h6>
                  <b>Net Payable</b>
                </h6>
                {paymentDetails.data.length ? (
                  paymentDetails.data.length > 1 ? (
                    <h6>
                      <b>
                        ₹
                        {paymentDetails.data.reduce(
                          (a, b) => a?.ServiceAmount + b?.ServiceAmount
                        )}
                      </b>
                    </h6>
                  ) : (
                    <h6>
                      <b>₹{paymentDetails.data[0].ServiceAmount}</b>
                    </h6>
                  )
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {couponsData?.length > 0 && (
        <div style={{ width: "65.5%", paddingLeft: "18px" }}>
          <div
            className="mb-2 mt-2"
            style={{
              border: "1px solid rgb(0, 125, 198)",
              height: "50px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <b>
              {" "}
              {validateCouponData?.IsValidCoupon
                ? "Coupon Applied"
                : "Apply Coupon"}{" "}
            </b>
            {validateCouponData?.IsValidCoupon ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setValidateCouponData({});
                }}
              >
                Remove Coupon
              </div>
            ) : (
              <Icon
                onClick={() => {
                  if (validateCouponData?.IsValidCoupon) return;
                  setShowCoupons(true);
                  // navigate("all-coupons");
                }}
                fontSize={"20px"}
                style={{
                  width: "35px",
                  cursor: "pointer",
                  height: "30px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  backgroundColor: "rgb(128, 128, 128, 0.3)",
                }}
                icon="fe:arrow-down"
                color="rgb(0, 125, 198)"
                rotate={3}
              />
            )}
            {/* <select
            name="couponCode"
            id="rootField"
            className="form-select p-2 rootSelect"
            aria-label="Default select example"
            defaultValue={""}
          >
            <option value="">
              Apply Coupon
            </option>
            {dashboardData?.["Gender"]?.length &&
                  dashboardData["Gender"].map((item) => (
                    <option value={item?.Value}>{item?.Text}</option>
                  ))}
          </select> */}
          </div>
        </div>
      )}

      {/* <div className="coupon-container">
        <input
          value={couponValue}
          onChange={(e) => {
            setCouponValue(e.target.value);
          }}
          type="text"
          style={{ paddingLeft: "10px" }}
          placeholder="Enter voucher code"
        />
        <button
          onClick={() => {
            if (!couponValue) {
              toast.error("Enter your coupon");
              return;
            }
            let validCoupon = couponsData?.find(
              (a) => a?.CouponCode == couponValue
            );
            if (validCoupon) {
              console.log("valid");
            } else {
              toast.error("Inavlid coupon");
            }
            console.log(validCoupon, "--------------->WER");
          }}
        >
          Apply
        </button>
      </div> */}
      <div
        style={{
          width: "64.7%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 35px 5px 9px",
          marginLeft: "20px",
        }}
      >
        <b>Net Payable</b>
        <b>
          {" "}
          {paymentDetails.data.length ? (
            paymentDetails.data.length > 1 ? (
              <b>
                ₹
                {paymentDetails.data.reduce(
                  (a, b) => a?.ServiceAmount + b?.ServiceAmount
                )}
              </b>
            ) : (
              <b>₹{paymentDetails.data[0].ServiceAmount}</b>
            )
          ) : (
            ""
          )}
        </b>
      </div>
      {validateCouponData?.IsValidCoupon && (
        <div
          style={{
            width: "64.7%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 35px 5px 9px",
            marginLeft: "20px",
          }}
        >
          <b>Effective amount to pay</b>
          <b>
            {" "}
            {paymentDetails.data.length ? (
              paymentDetails.data.length > 1 ? (
                <b>
                  ₹
                  {eval(
                    paymentDetails.data.reduce(
                      (a, b) => a?.ServiceAmount + b?.ServiceAmount
                    ) - (validateCouponData?.DiscountAmount || "0")
                  )}
                </b>
              ) : (
                <b>
                  ₹
                  {eval(
                    paymentDetails.data[0].ServiceAmount -
                      (validateCouponData?.DiscountAmount || "0")
                  )}
                </b>
              )
            ) : (
              ""
            )}
          </b>
        </div>
      )}
      <div className="px-3 pb my-3">
        <button
          onClick={callPayment}
          disabled={!paymentDetails?.data?.length}
          type="submit"
          style={{
            ...rootButton,
            padding: "8px 50px",
            cursor: "pointer",
          }}
        >
          Proceed To Pay
        </button>
      </div>
      <MainModal
        open={open}
        handleClose={handleClose}
        Component={PaymentModal}
      />
    </div>
  );
};
function PaymentModal() {
  return (
    <React.Fragment>
      <Box sx={style} className="p-5">
        <div>
          <BiRotateRight id="transitin" />
          <h3 style={{ color: "#0d6efd" }}>Hang Tight !</h3>
          <p style={{ color: "#0d6efd" }}>Confirming your Appointment</p>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default PatientPayment;

var paymentSummaryStyle = {
  borderColor: "#007DC6",
  backgroundColor: "#E4F1F9",
};
