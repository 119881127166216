import React from "react";
import { cardOrientation } from "assets/util/common/root";
import "./index.css";
import { callGet } from "common/local";
import { POLICY_BASE_URL } from "server/server";
const PrivacyPolicy = () => {
  const userData = callGet("auth");
  return (
    <div
      className="patient_header mt-3 scroll-bar"
      style={{ ...cardOrientation }}
    >
      <h4>Privacy Policy</h4>
      <iframe
        className="privacy-policy"
        title={"Privacy policy page"}
        src={
          POLICY_BASE_URL +
          (userData?.policyLinks?.RefundPolicyFileName ||
            "privacypolicy_MAH.html")
        }
      ></iframe>
    </div>
  );
};
export default PrivacyPolicy;
