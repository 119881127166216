// export const SERVER = "http://192.168.29.82:7037"
// export const SERVER = "http://192.168.29.54:7090"
// export const SERVER = "http://localhost:7037"
// export const SERVER = "http://localhost:7090"

export const SERVER = "https://marengo-portal.weaddo.live";
// export const SERVER = "http://localhost:7037";
export const POLICY_BASE_URL = "https://patientappuat.marengoasia.com/";
export const imgUrl = () =>
  "https://patientappuat.marengoasia.com/Files/Doctor";

export const Specialty = () =>
  "https://patientappuat.marengoasia.com/Files/Specialisation";

export const ProfilePic =
  "https://patientappuat.marengoasia.com/Files/ProfilePic/";
