import React, { useState } from "react";
import mahospital from "../../assets/image/mahospitalmain.png";
import { useNavigate } from "react-router-dom";
import MainIcon from "../../assets/mainIcon/MainIcon";
import { LoadingButton } from "../../assets/loading/LoadingButton";
import API from "util/API/api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postAPIData } from "Hooks/Services";
import { toast } from "react-hot-toast";
import VerifyOtp from "./VerifyOtp";
import NewPassword from "./NewPassword";
import Loader from "Pages/Loader";

const schema = yup.object().shape({
  userid: yup
    .string()
    .matches(/^1?([1-9])(\d{9})/, "Phone number is not valid")
    .required("Number is required"),
});
const MobileNumberValidator = yup.object({
  userid: yup
    .string()
    .required("Number is required")
    .min(10, "Phone number is not valid")
    .matches(/(0|91)?[6-9][0-9]{9}/, "Phone number is not valid")
    .test("numberCheck", "Phone number is not valid", (val) =>
      val?.toString()?.startsWith(0)
        ? val?.length == 11
        : val?.toString()?.startsWith(91)
        ? val?.length == 12
        : val?.length == 10
    ),
});
function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("mobile-number");
  const [otpCode, setOtpCode] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { userid: "" },
    resolver: yupResolver(MobileNumberValidator),
  });
  // async function handleEmail(e) {
  //   e.preventDefault();
  //   let formValid = false;
  //   try {
  //     await yup
  //       .string()
  //       .matches(/[2-9]{2}\d{8}/, "Phone number is not valid")
  //       .required("Number is required")
  //       .validate(number);
  //     formValid = true;
  //     setFormError();
  //   } catch (err) {
  //     formValid = false;
  //     setFormError(err.message);
  //   }
  //   if (formValid) {
  //     handleOpen();
  //   }
  // }
  const getOtp = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({ ...API.ForgotPasswordGetOTP(data) });
      const getRes = resp?.data;
      if (getRes?.IsSuccessful) {
        toast.success(
          getRes?.FriendlyMessage?.split(" ").splice(0, 5).join(" ")
        );
        if (getRes?.Data?.length) {
          setOtpCode(getRes?.Data[0]);
          if (getRes?.Data[0]?.OTPCode) {
            setCurrentPage("verify-otp");
          }
        }
      } else {
        toast.error(getRes?.FriendlyMessage?.split(" ").splice(0, 5).join(" "));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getFormData = (data) => {
    getOtp(data);
  };
  return (
    <React.Fragment>
      <div
        className="container-fluid rootBackground d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <MainIcon top={0} left={10} width={350} />
        <div className="signUpBox shadow-lg bg-light buttonmobset">
          <div className="text-center">
            <img
              draggable="false"
              src={mahospital}
              height="auto"
              width="200"
              alt=""
            />
            <h3 className="text-primary" style={{ fontWeight: "900" }}>
              <strong>Forgot your password?</strong>
            </h3>
          </div>
          {currentPage == "mobile-number" && (
            <div className="ps-5 pe-5 pb-5">
              <div className="form-group mt-2">
                <input
                  {...register("userid", {
                    onChange: (e) => {
                      if (e?.target?.value?.length > 10) {
                        setValue("userid", mobileNumber);
                        return;
                      }
                      setMobileNumber(e?.target?.value);
                    },
                  })}
                  type="number"
                  maxLength="10"
                  name="userid"
                  className="form-control p-3 m-2"
                  aria-describedby="mobileHelp"
                  placeholder="Enter Registered Mobile No."
                />
                {errors?.["userid"] && (
                  <p className="errorText">
                    {errors?.["userid"]?.message?.toString()}
                  </p>
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  onClick={handleSubmit(getFormData)}
                  loading={loading}
                  name="Get OTP"
                />
              </div>
            </div>
          )}
          {currentPage == "verify-otp" && (
            <VerifyOtp
              loading={loading}
              setLoading={setLoading}
              mobileNumber={mobileNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onClick={() => {
                getOtp({
                  userid: mobileNumber,
                });
              }}
              otpCode={otpCode}
            />
          )}
          {currentPage == "update-password" && (
            <NewPassword
              loading={loading}
              mobileNumber={mobileNumber}
              setLoading={setLoading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              otpCode={otpCode}
            />
          )}
        </div>
      </div>
      {loading && (
        <div className="loader-background">
          {" "}
          <div className="centerLoaderLog">
            <Loader />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ForgotPassword;
