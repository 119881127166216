import React, { useEffect } from "react";
import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import { POLICY_BASE_URL } from "server/server";

const TermsConditions = () => {
  const userData = callGet("auth");
  return (
    <div
      className="patient_header mt-3 scroll-bar"
      style={{ ...cardOrientation }}
    >
      <h4>Terms & Conditions</h4>
      <iframe
        className="privacy-policy"
        title={"Terms & Conditions page"}
        src={
          POLICY_BASE_URL +
          (userData?.policyLinks?.TermsandConditionFileName ||
            "termscondition_MAH.html")
        }
      ></iframe>
    </div>
  );
};

export default TermsConditions;
