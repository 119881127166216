import React, { useEffect, useState } from "react";
import "./PatientRegister.css";
import { rootButton } from "assets/util/common/button";
import { cardOrientation } from "assets/util/common/cardOrientation";
import MainModal from "assets/modal/MainModal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import API from "util/API/api";
import usePost from "Hooks/usePost";
import { callGet } from "common/local";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { signupCredentialSchema } from "schema/allSchema";
import { toast } from "react-hot-toast";
import useLogOut from "Hooks/useLogout";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";

import {
  PatientRagistrationFormFields,
  PatientRegistrationFormValiation,
  getIndiaId,
} from "./utils";
import { postAPIData } from "Hooks/Services";
import Loader from "Pages/Loader";
import { Icon } from "@iconify/react";
import moment from "moment";
// import { useFormData } from "../../assets/util/common/context";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  height: 450,
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  height: 450,
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function PatientRegister() {
  const [open, setOpen] = React.useState(false);
  const [disableGenderSelect, setDisableGenderSelect] = useState(false);
  const [loading, getData, setApi] = usePost();
  const [Loading, setLoading] = useState(false);
  const [loadingReg, getRegData, setRegApi] = usePost();
  const [timer, setTimer] = useState(0);
  const [status, callLogout] = useLogOut();
  const [registered, setRegistered] = useState();
  const [registerErrMsg, setRegisterErrMsg] = useState("");
  let timerS;
  const [handleIds, setHandleIds] = useState({
    CountryId: [],
    StateId: [],
    CityId: [],
    AreaId: [],
    zipCode: "",
  });
  const [signupCredential, setSignupCredential] = useState(
    signupCredentialSchema
  );
  const [dashboardData, setDashboardData] = useState({});

  const [patientDetail, setPatientDeetail] = useState(
    PatientRagistrationFormFields
  );

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...PatientRagistrationFormFields },
    resolver: yupResolver(PatientRegistrationFormValiation),
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setPatientDeetail(PatientRagistrationFormFields);
    setOpen(false);
    setRegisterErrMsg("");
    setTimer(0);
    clearTimeout(timerS);
    setModalView(true);
  };
  var [modalView, setModalView] = useState(true);
  const getDashBoardData = async (body) => {
    try {
      setLoading(true);
      const resp = await postAPIData({ ...API.getdashboarddata(body) });
      const getDashboardData = resp?.data;
      if (getDashboardData.IsSuccessful) {
        setDashboardData(getDashboardData?.Data[0]);
        const countryId = getIndiaId(
          getDashboardData?.Data[0]?.Country,
          "INDIA"
        );
        await getStateData(countryId);
        setValue("CountryId", countryId);
        const nationalityID = getIndiaId(
          getDashboardData?.Data[0]?.Nationality,
          "Indian"
        );
        setValue("NationalityId", nationalityID);
      } else {
        callLogout("logout");
        toast.error(getDashboardData?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getStateData = async (data) => {
    try {
      setLoading(true);
      var body = {
        ...callGet("auth"),
        CountryId: data,
      };
      const stateResp = await postAPIData({
        ...API.getactivestatebycountry(body),
      });
      const DATA = stateResp?.data;
      if (DATA.IsSuccessful) {
        setHandleIds({
          ...handleIds,
          StateId: DATA.Data,
          CityId: [],
          AreaId: [],
        });
        setValue("CityId", "");
        setValue("AreaId", "");
        setValue("Zip", "");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getCityData = async (data) => {
    try {
      setLoading(true);
      var body = {
        ...callGet("auth"),
        StateId: data,
      };
      const CityResp = await postAPIData({
        ...API.getactivecitybystate(body),
      });
      const DATA = CityResp?.data;
      if (DATA.IsSuccessful) {
        setHandleIds((e) => ({ ...e, CityId: DATA.Data, AreaId: [] }));
        setValue("AreaId", "");
        setValue("CityId", "");
        setValue("Zip", "");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getAreaData = async (data) => {
    try {
      setLoading(true);
      var body = {
        ...callGet("auth"),
        CityId: data,
      };
      const AreaResp = await postAPIData({
        ...API.getActiveAreaByCity(body),
      });
      const DATA = AreaResp?.data;
      if (DATA.IsSuccessful) {
        setHandleIds({ ...handleIds, AreaId: DATA.Data });
        setValue("Zip", "");
        setValue("AreaId", "");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getZipCodeDetails = async (data) => {
    try {
      setLoading(true);
      const resp = await postAPIData({
        ...API.getZipCodeDetails(data),
      });
      if (!resp?.data?.IsSuccessful) {
        throw resp?.data;
      }
      if (!resp?.data?.Data?.length) {
        toast.error("No data found for this pincode");
        setValue("CityId", "");
        setValue("StateId", "");
        setValue("AreaId", "");
        return;
      }
      await getCityData(resp?.data?.Data?.[0]?.StateId);
      setHandleIds((e) => ({
        ...e,
        AreaId: resp?.data?.Data?.[0]?.Areas,
      }));
      setValue("CityId", resp?.data?.Data?.[0]?.CityId);
      setValue("StateId", resp?.data?.Data?.[0]?.StateId);
      setValue("AreaId", "");
      setValue("Zip", resp?.data?.Data?.[0]?.ZIPCode);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getZipData = async (data) => {
    try {
      setLoading(true);
      var body = {
        ...callGet("auth"),
        AreaId: data,
      };
      const ZipResp = await postAPIData({
        ...API.getActiveZipCodeByArea(body),
      });
      const DATA = ZipResp?.data;
      if (DATA.IsSuccessful) {
        setHandleIds({ ...handleIds, zipCode: DATA.Data?.[0]?.Value });
        setValue("Zip", DATA.Data?.[0]?.Value);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (getData) {
      if (getData?.IsSuccessful) {
        toast.success(getData?.FriendlyMessage);
        if (getData?.Data.length)
          setSignupCredential({
            ...signupCredential,
            OTPCode: getData?.Data[0].OTPCode,
          });
        setTimer(59);
        handleOpen();
      } else {
        toast.error(getData?.FriendlyMessage);
      }
    }
  }, [getData]);
  // handling registeration

  useEffect(() => {
    if (getRegData) {
      if (getRegData?.IsSuccessful) {
        toast.success(getRegData?.FriendlyMessage);
        if (getRegData?.Data?.length) {
          reset();
          setRegistered(getRegData?.Data?.[0]);
        }
      } else {
        // toast.error(getRegData?.FriendlyMessage);
        setRegistered("already-registered");
        setRegisterErrMsg(getRegData?.FriendlyMessage);
      }
    }
  }, [getRegData]);

  function callRegister() {
    setRegApi(API.registerwithus({ ...callGet("auth"), ...patientDetail }));
  }
  const getFormData = (data) => {
    data.DateofBirth = moment(new Date(data?.DateofBirth))?.format(
      "MM/DD/YYYY"
    );
    setPatientDeetail({ ...data });
    setSignupCredential({
      ...signupCredential,
      FullName: data.FirstName,
      userid: data.MobileNumber,
    });
    setApi(
      API.getRegistrationOtp({
        FullName: data.FirstName,
        userid: data.MobileNumber,
      })
    );
  };
  useEffect(() => {
    const authData = callGet("auth");
    var body = { ...authData, FacilityId: authData?.hospitalId };
    getDashBoardData(body);
  }, []);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    timerS = setTimeout(() => {
      if (timer > 0) {
        setTimer((t) => t - 1);
      }
    }, 1000);
    if (timer <= 0) clearTimeout(timerS);
  }, [timer]);
  return (
    <div className="container-fluid mt-3 p-5" style={cardOrientation}>
      <div className="patient_header  ">
        <h4>
          <b>Patient Registration</b>
        </h4>
      </div>
      <div>
        <div className="row mt-5">
          <div className="col-md-2">
            <div className="">
              <label htmlFor="name" className="form-label">
                Title*
              </label>
              <select
                {...register("TitleId", {
                  onChange: (e) => {
                    if (e.target?.value == 3) {
                      setValue("GenderId", "2");
                      setDisableGenderSelect(true);
                      clearErrors("GenderId");
                    } else if (e?.target?.value == 4 || e?.target?.value == 5) {
                      setValue("GenderId", "1");
                      clearErrors("GenderId");
                      setDisableGenderSelect(true);
                    } else {
                      setValue("GenderId", "");
                      setDisableGenderSelect(false);
                    }
                  },
                })}
                name="TitleId"
                className="form-select p-2 "
                id="rootField"
              >
                <option value="" selected>
                  Select{" "}
                </option>
                {dashboardData["Titles"]?.length &&
                  dashboardData["Titles"]?.map((item, i) => (
                    <option key={i} value={item?.Value}>
                      {item?.Text}
                    </option>
                  ))}
              </select>
              {errors?.["TitleId"] && (
                <p className="errorText">
                  {errors?.["TitleId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="name" className="form-label">
                Patient Name*
              </label>
              <input
                {...register("FirstName")}
                type="text"
                className="form-control p-2"
                id="name"
                placeholder="Enter Your Name"
                name="FirstName"
              />
              {errors["FirstName"] && (
                <p className="errorText">
                  {errors["FirstName"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-2">
            <div className="">
              <label htmlFor="gender" className="form-label">
                Gender*
              </label>
              <select
                disabled={disableGenderSelect}
                name="GenderId"
                id="rootField"
                // onChange={handleInput}
                {...register("GenderId")}
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select{" "}
                </option>
                {dashboardData?.["Gender"]?.length &&
                  dashboardData["Gender"].map((item, i) => (
                    <option key={i} value={item?.Value}>
                      {item?.Text}
                    </option>
                  ))}
              </select>
              {errors["GenderId"] && (
                <p className="errorText">
                  {errors?.["GenderId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="name" className="form-label">
                Marital Status*
              </label>
              <select
                name="MaritalStatusId"
                id="rootField"
                // onChange={handleInput}
                {...register("MaritalStatusId")}
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select{" "}
                </option>
                {dashboardData["MarritalStatus"] &&
                  dashboardData["MarritalStatus"].map((item, i) => (
                    <option key={i} value={item.Value}>
                      {item.Text}
                    </option>
                  ))}
              </select>
              {errors["MaritalStatusId"] && (
                <p className="errorText">
                  {errors["MaritalStatusId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* 2nd column */}
        <div className="row">
          <div className="col-md-4">
            <div className="">
              <label htmlFor="name" className="form-label">
                Date of Birth*
              </label>
              <Controller
                name="DateofBirth"
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        orientation="portrait"
                        value={field?.value || null}
                        className="Date"
                        inputFormat="MM/DD/yyyy"
                        maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            {...params}
                            style={{ width: "100%" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              {errors["DateofBirth"] && (
                <p className="errorText" style={{ marginTop: "10px" }}>
                  {errors["DateofBirth"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="MobileNumber" className="form-label">
                Mobile Number*
              </label>
              <input
                pattern="\d{10}"
                maxLength={10}
                {...register("MobileNumber")}
                type="text"
                className="form-control p-2"
                id="number"
                placeholder="Enter your phone number"
                name="MobileNumber"
              />
              {errors["MobileNumber"] && (
                <p className="errorText">
                  {errors["MobileNumber"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <input
                {...register("EmailId")}
                type="email"
                className="form-control p-2"
                id="email"
                placeholder="Enter email"
                name="EmailId"
              />
              {errors["EmailId"] && (
                <p className="errorText">
                  {errors["EmailId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* 3rd Column */}
        <div className="row"></div>
        {/* 4th Column */}
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="adress">Address*</label>
            <textarea
              // value={patientDetail.LocalAddress}
              // onChange={handleInput}
              {...register("LocalAddress")}
              className="form-control p-2 universsalFocus"
              rows="5"
              id="adress"
              name="LocalAddress"
              placeholder="Enter Your Address"
              style={{ resize: "none" }}
            ></textarea>
            {errors["LocalAddress"] && (
              <p className="errorText">
                {errors["LocalAddress"]?.message?.toString()}
              </p>
            )}
          </div>
        </div>
        {/* 5th Column */}
        <div className="row">
          <div className="col-md-4">
            <div className="">
              <label htmlFor="country" className="form-label">
                Country*
              </label>
              <select
                // value={patientDetail?.CountryId}
                // onChange={handleInput}
                {...register("CountryId", {
                  onChange: (e) => {
                    setPatientDeetail({
                      ...patientDetail,
                      CountryId: e.target.value,
                    });
                    getStateData(e?.target?.value);
                  },
                })}
                name="CountryId"
                className="form-select rootSelect"
                aria-label="Default select example"
                id="rootField"
              >
                <option value="" selected>
                  Select{" "}
                </option>
                {dashboardData["Country"]?.length &&
                  dashboardData["Country"].map((item, i) => (
                    <option key={i} value={item.Value}>
                      {item?.Text?.toUpperCase()}
                    </option>
                  ))}
              </select>
              {errors["CountryId"] && (
                <p className="errorText">
                  {errors["CountryId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="state" className="form-label">
                State*
              </label>
              <select
                {...register("StateId", {
                  onChange: (e) => {
                    setPatientDeetail({
                      ...patientDetail,
                      StateId: e.target.value,
                    });
                    getCityData(e?.target?.value);
                  },
                })}
                id="rootField"
                name="StateId"
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select{" "}
                </option>

                {handleIds?.StateId?.length ? (
                  handleIds?.StateId?.map((e, i) => (
                    <option key={i} value={e?.Value}>
                      {e?.Text?.toUpperCase()}
                    </option>
                  ))
                ) : (
                  <option disabled>Select Country first...</option>
                )}
              </select>
              {errors["StateId"] && (
                <p className="errorText">
                  {errors["StateId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="city" className="form-label">
                City*
              </label>
              <select
                value={getValues("CityId")}
                {...register("CityId", {
                  onChange: (e) => {
                    setPatientDeetail({
                      ...patientDetail,
                      CityId: e.target.value,
                    });
                    getAreaData(e?.target?.value);
                  },
                })}
                id="rootField"
                // onChange={handleInput}
                name="CityId"
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="">City </option>
                {handleIds?.CityId?.length ? (
                  handleIds?.CityId.map((e, i) => (
                    <option key={i} value={e?.Value}>
                      {e?.Text}
                    </option>
                  ))
                ) : (
                  <option disabled>Select State first...</option>
                )}
              </select>
              {errors["CityId"] && (
                <p className="errorText">
                  {errors["CityId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* 6th Column */}
        <div className="row">
          <div className="col-md-4">
            <div className="">
              <label htmlFor="area" className="form-label">
                Area
              </label>
              <select
                id="rootField"
                {...register("AreaId", {
                  onChange: (e) => {
                    getZipData(e.target.value);
                    setPatientDeetail({
                      ...patientDetail,
                      AreaId: e.target.value,
                    });
                  },
                })}
                name="AreaId"
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value={""}>Area</option>
                {handleIds?.AreaId?.length ? (
                  handleIds?.AreaId.map((e, i) => (
                    <option key={i} value={e.Value}>
                      {e.Text}
                    </option>
                  ))
                ) : (
                  <option disabled>Select City first...</option>
                )}
              </select>
              {errors["AreaId"] && (
                <p className="errorText">
                  {errors["AreaId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="mnumber" className="form-label">
                Pin Code
              </label>
              <input
                disabled={Loading || (getValues("Zip") && getValues("StateId"))}
                pattern="\d{6}"
                minLength={6}
                maxLength={6}
                {...register("Zip", {
                  onChange: (e) => {
                    const authData = callGet("auth");
                    if (e?.target?.value?.length === 6) {
                      getZipCodeDetails({
                        ZipCode: e?.target?.value,
                        ...authData,
                      });
                    }
                  },
                })}
                type="text"
                className="form-control p-2"
                id="pincode"
                placeholder="Enter Pin"
                name="Zip"
              />
              {errors?.["Zip"] && (
                <p className="errorText">
                  {errors["Zip"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <label htmlFor="area" className="form-label">
                Nationality*
              </label>
              <select
                {...register("NationalityId")}
                id="rootField"
                name="NationalityId"
                className="form-select p-2 rootSelect"
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select{" "}
                </option>
                {dashboardData["Nationality"]?.length &&
                  dashboardData["Nationality"].map((item, i) => (
                    <option key={i} value={item?.Value}>
                      {item?.Text?.toUpperCase()}
                    </option>
                  ))}
              </select>
              {errors["NationalityId"] && (
                <p className="errorText">
                  {errors["NationalityId"]?.message?.toString()}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="">
            <button
              type="Submit"
              onClick={handleSubmit(getFormData)}
              style={{
                ...rootButton,
              }}
            >
              Submit
            </button>
          </div>
          {(loading || Loading || loadingReg) && (
            <div className="loader-background">
              <div className="centerLoader1">
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>

      <MainModal
        open={open}
        handleClose={handleClose}
        Component={modalView ? Component : Component2}
        props={{
          setModalView,
          registerErrMsg,
          setRegisterErrMsg,
          patientDetail,
          signupCredential,
          callRegister,
          registered,
          dashboardData,
          timer,
          setTimer,
          timerS,
          loading,
          onClick: handleSubmit(getFormData),
          loadingReg,
          setOpen,
        }}
      />
    </div>
  );
}

function Component({ props }) {
  const {
    setModalView,
    patientDetail,
    registerErrMsg,
    setRegisterErrMsg = () => {},
    signupCredential,
    callRegister,
    loadingReg,
    timer,
    loading,
    setTimer = () => {},
    onClick,
    timerS,
    setOpen,
  } = props;
  const [OTP, setOTP] = useState();
  const [loadingO, getData, setApi] = usePost();
  const [Err, setErr] = useState("");

  useEffect(() => {
    if (getData) {
      if (getData?.IsSuccessful) {
        toast?.success(getData?.FriendlyMessage);
        setTimer(0);
        clearTimeout(timerS);
        setModalView(false);
        callRegister();
      } else {
        toast.error(getData?.FriendlyMessage);
      }
    }
  }, [getData]);
  async function handleOtpMatch() {
    let otpValid = false;
    try {
      await yup.string().min(4).required("Otp is required").validate(OTP);
      setErr("");
      otpValid = true;
    } catch (err) {
      setErr(err.message);
      otpValid = false;
    }

    if (otpValid) {
      setApi(API.matchRegistrationOtp({ ...signupCredential, OTP: OTP }));
    }
  }
  return (
    <Box sx={styles}>
      <div
        onClick={() => {
          setOpen(false);
          setTimer(0);
          setRegisterErrMsg("");
          setModalView(true);
          clearTimeout(timerS);
        }}
        className="cross-icon-confirm"
      >
        <Icon fontSize={"20px"} icon="maki:cross" color="#0d6efd" />
      </div>
      {(loadingO || loading) && (
        <div className="loader-background">
          <div className="centerLoader1">
            <Loader />
          </div>
        </div>
      )}
      <div>
        <Typography id="modal-modal-title" variant="h6" component="h4">
          {" "}
          <b className="patient_otp">PLEASE ENTER OTP </b>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Enter One Time Password (OTP) Sent To Your Mobile No
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <input
            pattern="\d{4}"
            onChange={(e) => {
              if (e?.target?.value?.length > 4) return;
              setOTP(e.target.value);
            }}
            name="otp"
            value={OTP}
            type="number"
            className="form-control p-3"
            placeholder="Please Enter OTP"
          />
          {Err && <p className="errorText">{Err}</p>}
        </Typography>
        <div
          onClick={onClick}
          style={{
            color: "rgb(255, 111, 15)",
            cursor: timer === 0 && "pointer",
            textDecoration: "underline",
            pointerEvents: timer > 0 && "none",
          }}
        >
          {timer > 0 ? `Resend OTP in ${timer}` : "Resend OTP"}
        </div>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 3, textAlign: "center" }}
        >
          <button
            type="submit"
            style={{ ...rootButton }}
            onClick={handleOtpMatch}
          >
            Register
          </button>
        </Typography>
      </div>
    </Box>
  );
}

function Component2({ props }) {
  const {
    registered,
    setModalView,
    registerErrMsg,
    setRegisterErrMsg,
    patientDetail,
    loadingReg,
    dashboardData,
    setOpen,
    setTimer,
    timerS,
  } = props;
  const authData = callGet("auth");
  return (
    <Box sx={style}>
      <div
        onClick={() => {
          setOpen(false);
          setTimer(0);
          setRegisterErrMsg("");
          setModalView(true);
          clearTimeout(timerS);
        }}
        className="cross-icon-confirm"
      >
        <Icon fontSize={"20px"} icon="maki:cross" color="#0d6efd" />
      </div>
      {/* <div style={{position:'absolute',right:'20px',fontSize:'2rem',cursor:'pointer'}} onClick={()=>setModalView(false)}>X</div> */}
      {loadingReg ? (
        <div className="loader-background">
          <div className="centerLoader1">
            <Loader />
          </div>
        </div>
      ) : registered === "already-registered" ? (
        <div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h4"
            sx={{ textAlign: "center" }}
          >
            {" "}
            <b className="patient_otp">
              {authData?.hospitalName || "MARENGO ASIA HOSPITALS"}{" "}
            </b>
          </Typography>
          <div className="d-flex justify-content-between ">
            <Typography id="modal-modal-description" sx={{ mt: 4 }}>
              <b id="maringo-color">
                {registerErrMsg || "Patient Already Registered"}
              </b>
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h4"
            sx={{ textAlign: "center" }}
          >
            {" "}
            <b className="patient_otp">
              {authData?.hospitalName || "MARENGO ASIA HOSPITALS"}{" "}
            </b>
          </Typography>
          <div className="d-flex justify-content-between ">
            <Typography id="modal-modal-description" sx={{ mt: 4 }}>
              <span style={{ color: "#007DC6", fontSize: "20px" }}>
                Patient Name:
              </span>{" "}
              <b id="maringo-color">{registered?.Name}</b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 4 }}>
              <b style={{ color: "#FF6F0F", fontWeight: 900 }}>
                UHID {registered?.UHID}
              </b>
            </Typography>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            Date of Birth:
            <b id="maringo-color"> {patientDetail?.DateofBirth}</b>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            Gender:
            <b id="maringo-color">
              {" "}
              {dashboardData?.["Gender"].find(
                (e) => e?.Value == patientDetail?.GenderId
              )?.Text || ""}
            </b>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            please note the{" "}
            <b style={{ fontWeight: 800 }}> UHID {registered?.UHID}</b> for
            future references
          </Typography>
        </div>
      )}
    </Box>
  );
}
export default PatientRegister;
