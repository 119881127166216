import React, { useEffect, useRef, useState } from "react";
import { cardOrientation } from "../../../assets/util/common/cardOrientation";
import doctors from "../../../assets/image/doctor.png";
import { rootButton } from "../../../assets/util/common/button";
import back from "../../../assets/mainIcon/icons/Vector.png";
import { postAPIData, postFormAPIData } from "Hooks/Services";
import API from "util/API/api";
import { callGet, callSet } from "common/local";
import { toast } from "react-hot-toast";
import CancelAppointment from "../CancelAppointment";
import Loader from "Pages/Loader";
import {
  isTimeDifference15Mins,
  isTimeDifference24Hours,
} from "Pages/Patientregister/utils";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "assets/loading/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { postThunk } from "Action/middleware/thunk";
import actions from "Action/action";

const mainDiv = { ...cardOrientation };
const icon = { transform: "rotate(90deg)" };

const MyAppointmentDetails = ({
  loading,
  setLoading = () => {},
  setValue,
  singleAppoint,
  searchParams,
  setSearchParams = () => {},
}) => {
  const dispatch = useDispatch();
  const appDetails = useSelector((state) => state?.R_AppointmentDetails);
  const [details, setDetails] = useState({});
  const userData = callGet("auth");
  const [isCancel, setIsCancel] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const getDoctorFees = () => {
    if (
      (appDetails?.data?.DoctorId || searchParams?.get("doctor_id")) &&
      (appDetails?.data?.AppointmentId || searchParams?.get("id"))
    ) {
      const body = {
        ...userData,
        DoctorId: appDetails?.data?.DoctorId || searchParams?.get("doctor_id"),
        FacilityId: userData?.hospitalId,
        UHID: details?.UHID,
        TimeSlot: details?.TimeSlot,
        AppointmentDate: details?.AppointmentDate,
        AppointmentId:
          appDetails?.data?.AppointmentId || searchParams?.get("id"),
      };
      dispatch(postThunk(API.A_getdoctorfeesDetails(body)));
      navigate(
        "/dashboard/book-appointment/doctList/appointDoc/patientName/payment"
      );
    }
  };
  const appointmentDetails = async (data) => {
    try {
      setLoading(true);
      const detailsResp = await postAPIData({
        ...API.getAppointmentDetails({ ...userData, ...data }),
      });
      if (!detailsResp?.data?.IsSuccessful) {
        setDetails({});
        toast.error(
          detailsResp?.data?.FriendlyMessage || "Something went wrong"
        );
        return;
      }
      setDetails(detailsResp?.data?.Data?.[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const rescheduleAppointment = async (data) => {
    try {
      setLoading(true);
      const rescheduleResp = await postAPIData({
        ...API.rescheduleAppointment({
          ...userData,
          ...data,
        }),
      });
      if (!rescheduleResp?.data?.IsSuccessful) {
        toast.error(
          rescheduleResp?.data?.FriendlyMessage || "Something went wrong"
        );
        return;
      } else {
        toast.success(rescheduleResp?.data?.FriendlyMessage);
      }
      await appointmentDetails({
        UHID: singleAppoint?.UHID,
        AppointmentId: singleAppoint?.AppointmentId,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const uplaodPrescription = async (data) => {
    try {
      setLoading(true);
      const resp = await postFormAPIData({
        ...API.uploadPrescription(data),
      });
      if (!resp?.data?.IsSuccessful) {
        toast.error(resp?.data?.FriendlyMessage || "Something went wrong");
        return;
      } else {
        toast.success(resp?.data?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (data) => {
    rescheduleAppointment({
      AppointmentId: data,
    });
  };
  useEffect(() => {
    if (singleAppoint?.AppointmentId && singleAppoint?.UHID) {
      appointmentDetails({
        UHID: singleAppoint?.UHID,
        AppointmentId: singleAppoint?.AppointmentId,
      });
    }
  }, [singleAppoint?.UHID, singleAppoint?.AppointmentId, isCancel]);
  const handleCancelClick = () => {
    setIsCancel(true);
  };
  const getData = async (data) => {
    if (data?.size / (1024 * 1024) > 2) {
      toast.error("Max. size(2MB) exceeded");
      return;
    }
    const formData = new FormData();
    formData?.append("transactionId", "");
    formData?.append("appointmentId", details?.AppointmentId);
    formData.append("uhid", details?.UHID);
    formData.append("authData", JSON.stringify(userData));
    formData.append("file", data);
    uplaodPrescription(formData);
  };
  if (isCancel)
    return (
      <CancelAppointment
        loading={loading}
        setLoading={setLoading}
        userData={userData}
        data={details}
        cancel={isCancel}
        setCancel={setIsCancel}
      />
    );
  return (
    <div className="container-fuild mt-2 p-4" style={{ ...cardOrientation }}>
      <div className="row">
        <div className="col-12">
          <div className=" d-flex align-items-center">
            <div>
              <div className="media d-flex ">
                <img id="profile" draggable="false" src={doctors} alt="" />
                <div class="media-body ms-3 ">
                  <h6 class="mt-0">
                    <b>{details?.DoctorName || "Doctor name"}</b>{" "}
                  </h6>
                  <p>{details?.Source}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="contianer-fluid mt-2 p-4" style={{ ...mainDiv }}>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {isTimeDifference24Hours(details?.AppointmentOn) &&
            !details?.IsCancelled &&
            details?.IsCancellable && (
              <button
                onClick={handleCancelClick}
                className="reschedule-text-center"
              >
                Cancel appointment
              </button>
            )}
          {details?.RescheduleTimeSlot &&
            !details?.isAppointmentRescheduled && (
              <button
                onClick={() => handleClick(singleAppoint?.AppointmentId)}
                className="reschedule-text-center"
              >
                Reschedule appointment
              </button>
            )}
          <button
            className="btn-text-center"
            onClick={() => {
              setValue(1);
              if (searchParams?.has("id")) {
                searchParams?.delete("id");
                setSearchParams(searchParams);
              }
            }}
          >
            <img style={icon} draggable="false" src={back} alt="go back" />
            <p className="back-btn-text">Go Back</p>
          </button>
        </div>

        <div className="row mb-5">
          <div className="col-12">
            <table className="mt-3" style={{ width: "500px" }}>
              <tr style={{ lineHeight: "30px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>UHID:</td>
                <td style={{ fontWeight: "500" }}>
                  {" "}
                  {details?.UHID || "----"}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>
                  Patient Name:
                </td>
                <td style={{ fontWeight: "500", color: "#FF6F0F" }}>
                  {" "}
                  {details?.PatientName}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>Amount:</td>
                <td style={{ opacity: ".7" }}>
                  {" "}
                  {details?.Amount ? "₹" + details?.Amount : "00.00"}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>
                  Appointment Id:
                </td>
                <td style={{ opacity: ".7" }}>
                  {" "}
                  {details?.AppointmentId || "----"}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>
                  Appointment Date & Time:
                </td>
                <td style={{ opacity: ".7" }}>
                  {" "}
                  {details?.isAppointmentRescheduled
                    ? details?.RescheduleTimeSlot || "----"
                    : details?.AppointmentOn || "----"}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>
                  Reschedule Date and Time:
                </td>
                <td style={{ opacity: ".7" }}>
                  {" "}
                  {details?.isAppointmentRescheduled
                    ? "Rescheduled"
                    : "Not rescheduled"}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>Source:</td>
                <td style={{ opacity: ".7" }}> {details?.Source || "----"}</td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>
                  Appointment Type:
                </td>
                <td style={{ opacity: ".7" }}>
                  {" "}
                  {details?.AppointmentTypeName || "----"}
                </td>
              </tr>
              <tr style={{ lineHeight: "40px" }}>
                <td style={{ color: "#007DC6", fontWeight: "500" }}>
                  Status :
                </td>
                <td style={{ color: details?.StatusColor, opacity: ".7" }}>
                  {" "}
                  {details?.Status || "----"}
                </td>
              </tr>
            </table>
          </div>
        </div>
        {isTimeDifference15Mins(details?.AppointmentOn) &&
          details?.IsSlotFreeze &&
          details?.IsPayable && (
            <div style={{ marginBottom: "20px" }} className="row">
              <div className="col">
                <button
                  onClick={() => {
                    const txnId = `PA1${new Date().getTime()}`;
                    callSet({
                      name: "auth",
                      data: {
                        ...userData,
                        TxnID: txnId,
                      },
                    });
                    dispatch(actions?.A_appointmentDetails(details));
                    getDoctorFees();
                  }}
                  className="btn"
                  style={rootButton}
                >
                  Pay Now
                </button>
              </div>
            </div>
          )}
        <div className="row mb-3">
          <div className="col-12">
            <p style={{ opacity: ".7" }}>
              Upload attachments (Max 3 Attachment, PDF/Image)
            </p>
          </div>
        </div>

        <input
          onChange={(e) => {
            getData(e.target.files?.[0]);
          }}
          type="file"
          ref={inputRef}
          accept="application/pdf, image/*"
          style={{ display: "none" }}
        />
        <div className="row">
          <div className="col">
            <button
              onClick={() => {
                inputRef.current.click();
              }}
              className="btn"
              style={rootButton}
            >
              Upload
            </button>
          </div>
        </div>
        {loading && (
          <div className="loader-background">
            <div className="centerLoader1">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAppointmentDetails;
