import React, { useEffect, useState } from "react";
import { cardOrientation } from "assets/util/common/cardOrientation";
import { rootButton } from "assets/util/common/button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MainModal from "assets/modal/MainModal";
import { callGet } from "common/local";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import API from "util/API/api";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import { LoadingButton } from "assets/loading/LoadingButton";
import { postAPIData } from "Hooks/Services";
import { Icon } from "@iconify/react";

const style = {
  // boxSizing:"border-box",
  position: "absolute",
  left: "30.25%",
  right: "37.19%",
  top: "20.93%",
  bottom: "40.67%",
  background: " #FFFFFF",
  opacity: "1",
  border: "1px solid #007DC6",
  boxShadow: "1px 1px 20px 1px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
};
const ValidatorSchema = yup.object().shape({
  question: yup
    .string()
    .required("Query is required")
    .test(
      "checkWords",
      "Minimum 5 words required",
      (val) => val.split(" ").filter((e) => e !== "").length >= 5
    )
    .test(
      "maxChars",
      "Max. character limit exceeded",
      (val) => val.split("").filter((e) => e != " ").length <= 500
    ),
  MobileNumber: yup
    .string()
    .required("Number is required")
    .min(10, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number"),
});
export default function QuestionAsk() {
  const sampleCard = { ...cardOrientation, minHeight: "40%" };
  const authData = callGet("auth");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileNumberText, setMobileNumberText] = useState("");
  const [userInput, setInput] = useState({
    question: "",
    MobileNumber: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      question: "",
      MobileNumber: authData?.userId,
    },
    resolver: yupResolver(ValidatorSchema),
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInput({ ...userInput, question: "" });
    setValue("question", "");
  };
  const saveQuestion = async (data) => {
    try {
      setLoading(true);
      const saveQuestionResp = await postAPIData({
        ...API.adduserquestions(data),
      });
      const getResponse = saveQuestionResp?.data;
      if (getResponse?.IsSuccessful) {
        setValue("question", "");
        handleOpen();
      } else {
        toast.error(getResponse?.FriendlyMessage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInput({ ...userInput, MobileNumber: authData?.userId });
  }, []);
  const getFormData = (data) => {
    setInput({ ...data });
    var body = { ...authData, ...data };
    saveQuestion(body);
  };
  return (
    <div
      className="container-fluid p-5 mt-2 payment-summary"
      style={sampleCard}
    >
      <h3 style={{ color: "#007DC6" }}>
        <b>Questions? Ask us</b>
      </h3>
      <div>
        <div className="col-md-4 mt-3 mb-3">
          <input
            type="number"
            name="MobileNumber"
            required
            {...register("MobileNumber", {
              onChange: (e) => {
                if (e?.target?.value?.length > 10) {
                  setValue("MobileNumber", mobileNumberText);
                  return;
                }
                setMobileNumberText(e?.target?.value);
              },
            })}
            className="form-control p-2"
            placeholder="Mobile Number*"
          />
          {errors?.["MobileNumber"] && (
            <p className="errorText">
              {errors?.["MobileNumber"]?.message?.toString()}
            </p>
          )}
        </div>
        <div class="col-md-8">
          <textarea
            className="form-control"
            name="question"
            {...register("question")}
            style={{ resize: "none" }}
            rows="4"
            placeholder="Query* (Max 500 characters)"
          ></textarea>
          {errors["question"] && (
            <p className="errorText">
              {errors["question"]?.message?.toString()}
            </p>
          )}
        </div>

        <div className="mt-3">
          {/* <button type="submit" style={{...rootButton,padding:"8px 50px"}}>submit</button> */}
          <LoadingButton
            style={{ padding: "8px 50px" }}
            loading={loading}
            onClick={handleSubmit(getFormData)}
            name={"Submit"}
          />
        </div>
      </div>

      <MainModal
        open={open}
        props={{ handleClose }}
        handleClose={handleClose}
        Component={Component}
      />
    </div>
  );
}

function Component({ props }) {
  const { handleClose } = props;
  const authData = callGet("auth")?.hospitalName?.split(",");
  return (
    <Box sx={style} className="p-5 newtext">
      <div
        onClick={() => {
          handleClose();
        }}
        className="cross-icon-confirm"
      >
        <Icon fontSize={"20px"} icon="maki:cross" color="#0d6efd" />
      </div>
      <Typography id="modal-modal-title" variant="h6" component="div">
        <h5>
          {" "}
          <b style={{ color: "#007DC6" }}> {authData?.join(" ")}</b>
        </h5>
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
        We have successfully received your query At <br />{" "}
        {authData?.[0] + "," || "Marengo QRG Hospital,"}
        {authData?.[1]?.trim() === "Faridabad" && "Sec-16,"}
        {authData?.[1] || "FARIDABAD"}
      </Typography>
      <button
        type="button"
        style={{ ...rootButton, padding: "8px 50px" }}
        onClick={handleClose}
      >
        Ok
      </button>
    </Box>
  );
}
