import React from "react";
import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import { POLICY_BASE_URL } from "server/server";

const TeleConsultationPolicy = () => {
  const userData = callGet("auth");
  return (
    <div
      className="patient_header mt-3 scroll-bar"
      style={{ ...cardOrientation }}
    >
      <h4>Tele-Consultation Policy</h4>
      <iframe
        className="privacy-policy"
        title={"Tele-consultation Policy"}
        src={POLICY_BASE_URL + userData?.policyLinks?.ConsentFileName}
      ></iframe>
    </div>
  );
};

export default TeleConsultationPolicy;
